import { Component } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  template: `<div id="main-app-element" [ngClass]="{'dark-theme': themeService.darkTheme}">
  <router-outlet></router-outlet>
  </div>`,
  styles: ['#main-app-element {height: 100%;}']
})
export class AppComponent {

  constructor(public themeService: ThemeService){}
  
}