import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth'
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription, Observable } from 'rxjs';
import { take, map, retry } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Sentry from "@sentry/browser";
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserGeneral } from '../models/user.model';
import { Promo } from '../models/subscription/promo.model';
import { AnalyticService } from './analytic.service';
import { ClientJS } from 'clientjs'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //important: the min date determines the starting date when email verification is enforced
  //current date is set from 1 September 2023
  private minDate: Date = new Date(2023, 8, 1)
  private user: firebase.User = null;
  public requests: { urlWithParams: string, body: Object, method: string, timestamp: Date }[] = []
  sessionId: any;
  fid: string;

  constructor(
    private http: HttpClient,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private snackbar: SnackbarService,
    private afdb: AngularFireDatabase,
    private analyticService: AnalyticService,
  ) {
    this.sessionId = window.localStorage.getItem('__sid')
    const client = new ClientJS()
    this.fid = client.getFingerprint()

    this.angularFireAuth.authState.subscribe(async auth => {
      this.user = auth;

      if (this.user) {
        if (environment.environment !== 'prod') {
          console.log(this.user.uid);
        }
        if ((<any>window).chrome !== undefined) {
          try {
            const token = await this.getCustomToken().toPromise();
            (<any>window).chrome.runtime.sendMessage(environment.extensionId, { type: 'auth', token })
          } catch (error) { }
        }
        Sentry.configureScope((scope) => {
          const sentryUser = {}
          if (this.user && this.user.email) {
            sentryUser['email'] = this.user.email
          }
          if (this.currentUserId) {
            sentryUser['id'] = this.currentUserId
          }
          scope.setUser(sentryUser);
        });

        if (this.router.url.includes('sign') && this.user && this.user.emailVerified) {
          try {
            const accountInfo = await this.afdb
              .object<UserGeneral>(`/users/${this.currentUserId}/general`)
              .valueChanges()
              .pipe(take(1))
              .toPromise();

            if (accountInfo) {
              // this.router.navigate(['/dashboard'])
            }
          } catch (error) {

          }
        }
      }
    });
  }


  /**
   * Returns true if user is authenticated
   *
   * @readonly
   * @type {boolean}
   * @memberof AuthService
   */
  get authenticated(): boolean {
    return this.user !== null;
  }

  /**
   * Returns current user data
   *
   * @readonly
   * @type {firebase.User | null}
   * @memberof AuthService
   */

  get currentUser(): firebase.User | null {
    return this.authenticated ? this.user : null;
  }

  /**
   * Returns current user UID
   *
   * @readonly
   * @type {string}
   * @memberof AuthService
   */
  get currentUserId(): string {
    return this.authenticated ? this.user.uid : '';
  }

  /**
   * Returns current user email
   *
   * @readonly
   * @type {string}
   * @memberof AuthService
   */
  get currentUserEmail(): string {
    return this.authenticated ? this.user.email : '';
  }

  /**
   * Returns auth state observable
   *
   * @readonly
   * @type {Observable<any>}
   * @memberof AuthService
   */
  get authstate(): Observable<any> {
    return this.angularFireAuth.authState;
  }

  get emailVerified(): Promise<boolean> {
    return new Promise(resolve => {
      if (this.currentUser.emailVerified) {
        return resolve(true);
      } else {
        this.afdb
          .object(`/users/${this.currentUserId}/stats/signUpDate`)
          .snapshotChanges()
          .pipe(take(1))
          .toPromise()
          .then(data => {
            if (!_.isNull(data.payload.val())) {
              moment()
                .subtract(1, 'days')
                .isAfter(moment(data.payload.val()))
                ? resolve(false)
                : resolve(true);
            } else {
              this.afdb
                .object(`/users/${this.currentUserId}/stats/scansRun/amount`)
                .snapshotChanges()
                .pipe(take(1))
                .toPromise()
                .then(amount => {
                  amount.payload.val() < 2 ? resolve(true) : resolve(false);
                });
            }
          });
      }
    });
  }

  isEmailVerified(reqDate: boolean = true): Boolean {
    if (reqDate) {
      const createdDate = new Date(this.user.metadata.creationTime)
      if (createdDate.getTime() >= this.minDate.getTime()) {
        return this.user.emailVerified
      } else {
        return true
      }
    }
    return this.user.emailVerified
  }

  /**
   * Add when user register in app, it also add to Segment analytic
   * @param uid - identifier in segment, it comes from user uid firebase
   * @param traits - additional data that you want to add in user Segment
   */
  updateSegmentAnalytic(uid: string, traits: any) {
    if (window['analytics']) {
      window['analytics'].identify(uid, {
        ...traits,
        uid
      });
    }
  }

  // for google ads event when signup success
  addDataLayerAnalytic() {
    if (window['dataLayer']) {
      window['dataLayer'] = window['dataLayer'] || [];

      window['dataLayer'].push({
        'event': 'new-signup',
      });
    }
  }

  async addCarbonAnalytic(name: string, email: string) {
    const option = {
      name,
      email,
      tool_name: 'ScanUnlimited'
    };

    if (window['biSignup']) {
      window['biSignup'](option);
    }
  }

  updateUser(data: any) {
    return this.afdb.object(`/users/${this.currentUserId}`).update(data);
  }

  updateUserPromo(promo: any) {
    return this.afdb.object(`/users/${this.currentUserId}`).update({ promo });
  }

  /**
   * Sign users up using email and password
   *
   * @param {string} email
   * @param {string} password
   * @memberof AuthService
   */
  signup(email: string, password: string, firstName: string, phone: string, promo: Promo, analytic: any = null): Promise<any> {
    const payload = {email, password, firstName, phone, promo}    
    return this.angularFireAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(async value => {
        //TODO: to secure the sign ups
        if (value.user && analytic) {
          const uid = value.user.uid;
          const email = value.user.email;
          this.updateSegmentAnalytic(uid, {
            ...analytic,
            email,
          });
        }


        return this.signIn(email, password)
      })
      .then(() => this.afdb.object(`/users/${this.currentUserId}/general`).update({ firstName, phone }))
      .then(() => this.sendEmailVerification())
      .then(() => {
        if (promo) {
          this.afdb.object(`/users/${this.currentUserId}`).update({ promo })
        }
      })
      .then(() => {
        // for google ads event when signup success

        this.addDataLayerAnalytic();
        // for carbob6 service
        this.addCarbonAnalytic(firstName, email);
      });
  }

  checkPartnerStackReferal(name: string, email: string) {
    const getCookie = (cname) => {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }


    var user = getCookie("growSumoPartnerKey");
    if (user != "") {

      const growsumo = window['growsumo']

      const newEmail = email.split('@').join('+scanunlimited@')

      growsumo.data.name = name
      growsumo.data.email = newEmail
      growsumo.data.customer_key = newEmail
      growsumo.data.host_domain = location.hostname
      growsumo.data.tool_name = "ScanUnlimited"
      growsumo.createSignup();


    } else {
      console.log('partner key not found')
    }

  }

  /**
   * Sign users in using email and password
   *
   * @param {string} email
   * @param {string} password
   * @memberof AuthService
   */
  signIn(email: string, password: string, redirect?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // function to sign users in with email and password
      return this.angularFireAuth.auth
        .signInWithEmailAndPassword(email, password)
        .then(value => {

          const authCheck: Subscription = this.authstate
            .pipe(map(user => !!user))
            .subscribe(async loggedIn => {
              if (loggedIn) {

                try {
                  const host = window.location.host

                  const sessionId = await this.createSession(host, this.fid).toPromise()

                  if (sessionId) {
                    const requireOTP = sessionId['requireOTP']
                    localStorage.setItem('__sid', sessionId['sessionId'])

                    if (requireOTP) {
                      this.router.navigate(['/session/otp'])
                    } else {
                      this.router.navigate(['/dashboard'], { replaceUrl: true })
                    }
                  }

                } catch (e) {
                  Sentry.captureException(e)
                }

                this.analyticService.login(email);

                authCheck.unsubscribe();
                const notCheck = this.afdb
                  .list(`/notifications/${this.currentUserId}/`, ref =>
                    ref.limitToLast(1)
                  )
                  .valueChanges()
                  .subscribe(async notifications => {
                    if (!_.isEmpty(notifications)) {
                      if (redirect) {
                        //this.router.navigate(['/dashboard']);

                      }
                      notCheck.unsubscribe();
                      resolve(true);
                    }
                  });
              }
            });
        })
        .catch(err => reject(err));
    });
  }

  /**
   * Sign users in via popup with Google
   *
   * @memberof AuthService
   */
  googleSignin(): Promise<void> {
    return this.angularFireAuth.auth.signInWithRedirect(
      new firebase.auth.GoogleAuthProvider().setCustomParameters({
        prompt: 'select_account'
      })).then(() => {
      })
  }

  /**
   * Request for OTP (2FA)
   * @param sessionId 
   * @returns 
   */
  requestOTP(sessionId: string): Promise<any> {
    //  console.log('requestOTP', sessionId)
    return this.http.post(`${environment.lambdaUrl}/otp/request`, { sid: sessionId }).toPromise()
  }

  /**
   * Validate the OTP code (2FA)
   * @param code 
   * @param sessionId 
   * @returns 
   */
  validateOTP(code: string, sessionId: string): Promise<any> {
    return this.http.post(`${environment.lambdaUrl}/otp/validate`, { sid: sessionId, code: code }).toPromise()
  }

  getRedirectResult() {
    return this.angularFireAuth.auth.getRedirectResult()
  }

  /**
   * Sign user out
   *
   * @memberof AuthService
   */
  async signOut() {
    localStorage.removeItem('__sid')
    await this.angularFireAuth.auth.signOut();
    // this.router.navigateByUrl('/session/signin').then(() => {
    //   setTimeout(async () => {
    //     await this.angularFireAuth.auth.signOut();
    //   }, 1000);
    // });
  }

  /**
   * update user password
   *
   * @param {string} newPass
   * @returns {firebase.Promise<any>}
   * @memberof AuthService
   */
  changePassword(newPass: string): Promise<any> {
    if (this.authenticated) {
      return this.user.updatePassword(newPass);
    }
  }

  /**
   * reauthenticate user to make changes to account
   *
   * @param {string} oldPass
   * @returns {firebase.Promise<any>}
   * @memberof AuthService
   */
  reauthenticate(oldPass: string): Promise<any> {
    if (this.authenticated) {
      const credentials = firebase.auth.EmailAuthProvider.credential(
        this.currentUserEmail,
        oldPass
      );
      return this.user.reauthenticateWithCredential(credentials)
    } else {
      throw new Error('not authenticated');
    }
  }

  /**
   * check if user auth provider is email/password
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  isEmailAccount(): boolean {
    if (this.authenticated) {
      return !!_.find(this.user.providerData, { providerId: 'password' })
    }
  }

  isGoogleAccount(): boolean {
    if (this.authenticated) {
      return !!_.find(this.user.providerData, { providerId: 'google.com' })
    }
  }

  getEmailPasswordProvider(): firebase.UserInfo {
    if (this.authenticated) {
      return _.find(this.user.providerData, { providerId: 'password' })
    }
  }

  sendEmailVerification(): Promise<boolean> {
    if (!this.currentUser.emailVerified) {
      return this.currentUser.sendEmailVerification().then(() => true);
    }
    return Promise.resolve(false);
  }

  get reauthGoogle(): Promise<any> {
    return this.currentUser.reauthenticateWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
  }

  /**
   * Submit request to reset password and email customer
   *
   * @param {string} email
   * @returns {firebase.Promise<any>}
   * @memberof AuthService
   */
  forgotPassword(email: string): Promise<any> {
    return this.angularFireAuth.auth.sendPasswordResetEmail(email);
  }

  /**
   * Verify password code from query string
   *
   * @param {string} code
   * @returns {firebase.Promise<any>}
   * @memberof AuthService
   */
  verifyPasswordResetCode(code: string): Promise<any> {
    return this.angularFireAuth.auth.verifyPasswordResetCode(code);
  }

  /**
   * confirm password code and reset pass to new pass
   *
   * @param {string} code
   * @param {string} newPass
   * @returns {firebase.Promise<any>}
   * @memberof AuthService
   */
  confirmPasswordReset(code: string, newPass: string): Promise<any> {
    return this.angularFireAuth.auth.confirmPasswordReset(code, newPass);
  }

  /**
   * confirm email verification with code
   *
   * @param {string} code
   * @returns {firebase.Promise<any>}
   * @memberof AuthService
   */
  confirmEmailVerification(code: string): Promise<any> {
    return this.angularFireAuth.auth.applyActionCode(code);
  }

  updateStripeEmail(): Observable<any> {
    return this.http.post(`${environment.newApiUrl}/api/v1/user/update-stripe-email`, {});
  }

  async linkEmailAuth(email: string, password: string): Promise<any> {
    await this.reauthGoogle
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    await firebase.auth().currentUser.linkWithCredential(credential)
    await this.sendEmailVerification()
  }

  async unLinkEmailAuth(providerId: string) {
    await this.reauthGoogle
    await this.currentUser.unlink(providerId)
  }

  getCustomToken(): Observable<any> {
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.get(`${environment.newApiUrl}/api/v1/auth/token`, requestOptions)
  }

  /**
   * Change email:
   * we send email verification first and show status "Pending Verification" base from @var user.emailVerified
   */
  async changeEmail(email: string) {
    if (email && this.user) {
      await this.user.verifyBeforeUpdateEmail(email);
      // await this.angularFireAuth.auth.currentUser.updateEmail(email);
      await this.updateUser({ email });
    }
  }

  getSession(): Observable<any> {
    //const sid = sessionStorage.getItem('__sid')
    return this.afdb.list(`/sessions/${this.currentUserId}/`).snapshotChanges();
  }

  createSession(host, fid): Observable<any> {
    return this.http.post(`${environment.newApiUrl}/api/v1/auth/session`, { fid: `${fid}`, host });
  }

  updateSession(sessionId, host, fid): Observable<any> {
    return this.http.put(`${environment.newApiUrl}/api/v1/auth/session/${sessionId}`, { host, fid: `${fid}` });
  }
}
