import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { Router } from '@angular/router';
import { DatabaseService } from '../../../shared/services/database.service';
import { UserScanSettings } from '../../../shared/models/user.model';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';
import { Subscription } from 'rxjs';
import { ThemeService } from 'app/shared/services/theme.service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Output() sidenavToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() sidenavToggleEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() debugEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() unreadNotifications: number;
  itemDetailSearchForm: FormGroup;
  marketplaces: Array<string> = [];
  marketplacesSub: Subscription;
  conditions: Array<'new' | 'used'> = ['new', 'used']
  userSettings: UserScanSettings;
  selectedMarketplace: string = 'US'
  selectedCondition: 'new' | 'used' = 'new'
  env = environment.environment

  regions = {
    NA: ['US', 'MX', 'CA'],
    EU: ['UK', 'FR', 'IT', 'DE', 'ES'],
    BR: ['BR'],
    IN: ['IN'],
    CN: ['CN'],
    FE: ['AU', 'JP']
  };

  constructor(
    private authService: AuthService,
    private snack: SnackbarService,
    private router: Router,
    private db: DatabaseService,
    public themeService: ThemeService
  ) { }

  async ngOnInit() {
    this.itemDetailSearchForm = new FormGroup({
      searchValue: new FormControl(null, [Validators.required])
    });

    // fetch available marketplaces
    this.marketplacesSub = this.db.getVerifiedSPMarketplaces().subscribe(
      async marketplaces => {
        const mwsMarketplaces = await this.db.getVerifiedMWSMarketplaces().pipe(take(1)).toPromise();
        this.marketplaces = [];

        if (marketplaces.length >= mwsMarketplaces.length) {
          marketplaces.forEach(marketplace => {
            this.marketplaces.push(...this.regions[marketplace]);
          });
        } else {
          if (new Date().getTime() > this.db.deadlineAmazon) {
            marketplaces.forEach(marketplace => {
              this.marketplaces.push(...this.regions[marketplace]);
            });
          } else {
            mwsMarketplaces.forEach(marketplace => {
              this.marketplaces.push(...this.regions[marketplace]);
            });
          }
        }
      }
    );

    this.db.scanSettings.then(data => {
      this.userSettings = data;
      if (this.marketplaces.length !== 0) {
        this.selectedMarketplace = this.marketplaces[0]
      }
      if (this.userSettings && this.userSettings.defaultMarketplace) {
        this.selectedMarketplace = this.userSettings.defaultMarketplace;
      }
      if (this.userSettings && this.userSettings.condition) {
        this.selectedCondition = this.userSettings.condition
      }
    });
  }

  toggleSidenav() {
    this.sidenavToggle.emit();
  }

  toggleSidenavEnd() {
    this.sidenavToggleEnd.emit();
  }

  async signout() {
    await this.authService.signOut();
    // (<any>window).Intercom('shutdown');
    window.location.href = '/session/signin';
  }

  search() {
    if (this.itemDetailSearchForm.valid) {
      const formVals = this.itemDetailSearchForm.value;
      formVals.searchValue = formVals.searchValue.replace(/[^a-zA-Z0-9 ]/g, '');
      this.itemDetailSearchForm.reset()
      this.router.navigate([
        `/product-detail/${this.selectedMarketplace}/${formVals.searchValue}/${this.selectedCondition}`
      ]);
    } else {
      this.snack.showSnackbar('Search value must be a UPC, EAN, ISBN, ASIN, or Keyword');
    }
  }

  selectMarketplace(marketplace: Marketplace) {
    this.selectedMarketplace = marketplace;
  }

  selectCondition(condition: 'new' | 'used') {
    this.selectedCondition = condition;
  }

  switchEnv() {
    if (this.env === 'prod') {
      window.location.href = "https://beta.scanunlimited.com"
    } else {
      window.location.href = "https://app.scanunlimited.com"
    }
  }

  debug() {
    this.debugEmitter.emit()
  }

  ngOnDestroy() {
    if (this.marketplacesSub) this.marketplacesSub.unsubscribe()
  }

}
