export type MarketplaceCurrency = 'USD' | 'CAD' | 'MXN' | 'EUR'

export const marketplaceCurrencies = {
    US: 'USD',
    CA: 'CAD',
    MX: 'MXN',
    UK: 'GBP',
    DE: 'EUR',
    FR: 'EUR',
    ES: 'EUR',
    IT: 'EUR',
}

