import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public darkTheme: boolean

  constructor(private snackbar: SnackbarService) {
    try {
      this.darkTheme = localStorage.getItem('darkTheme') === 'true' ? true : false
    } catch (error) {
      this.darkTheme = false
    }
  }

  setTheme(){
    try {
      localStorage.setItem('darkTheme', String(this.darkTheme))
    } catch (error) {
      this.snackbar.showSnackbar('Failed to save theme preference')
    }
  }
}
