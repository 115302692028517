import { Component, Input } from '@angular/core';
import { UserNotification } from 'app/shared/models/userNotification.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  @Input() notificationsArray: UserNotification[];

  constructor(private router: Router) {}

  async notificationClick(link: string) {
    if(!this.router.url.includes(link)){
      await this.router.navigateByUrl('/-', {skipLocationChange: true})
      await this.router.navigate([link])
    }
  }
}
