import {
  Directive,
  ElementRef,
  Output,
  HostListener,
  EventEmitter
} from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  @Output()
  clickOutside: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  clickedOutside(el: Element) {
    // check if interacting with mat select
    if (
      (el.className && typeof el.className === 'string') &&
      (el.className.includes('edit-icon') ||
      el.className === 'mat-option-text' ||
      el.className === 'cdk-overlay-backdrop')
    ) {
      return null;
    }
    const clickedInside = this.elementRef.nativeElement.contains(el);
    if (!clickedInside) {
      this.clickOutside.emit(true);
    }
  }

  constructor(private elementRef: ElementRef) {}
}
