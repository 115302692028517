import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Product } from '../models/sis/product.model';
import { ProductDetail, ChildrenData, NewFees } from '../models/sis/product-detail.model';
import { Seller } from '../models/sis/seller.model';
import { Marketplace } from '../models/credentials-api/valid-marketplaces.model';
import { retry } from 'rxjs/operators';
import { ChartApi } from '../components/product-detail-core/product-detail-asin/keepa-chart/chart-api.model';

@Injectable({
  providedIn: 'root'
})
export class SisService {

  constructor(private http: HttpClient) { }

  getSearchArray(search: string, marketplace: string): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.newApiUrl}/api/v1/product-detail/keyword`, {
      params: {
        marketplace,
        search
      }
    })
  }

  getProductDetail(asin: string, marketplace: string): Observable<ProductDetail> {
    return this.http.get<ProductDetail>(`${environment.lambdaUrl}/product/details`, {
      // return this.http.get<ProductDetail>(`${environment.newApiUrl}/api/v1/product-detail/detail`, {
      params: {
        marketplace,
        asin,
        offers: "1"
      }
    })
  }

  getKeepaChart(marketplace: string, asin: string, day: number): Observable<{ salesRank: ChartApi, stats: { amazon: ChartApi, new: ChartApi } }> {
    return this.http.get<any>(`${environment.lambdaUrl}/product/graph`, {
      params: {
        marketplace,
        asin,
        days: day.toString(),
        format: 'unix',
        dataformat: 'chart-js',
      }
    })
  }

  // for now only take hazmat value
  getProductDetailv2(scanId: string, productId: string): Observable<ProductDetail> {
    return this.http.post<ProductDetail>(`${environment.newApiUrl}/api/v1/scan/${scanId}/detail?oid=${productId}`, {});
  }

  getFees(asin: string, marketplace: Marketplace, price: number): Observable<NewFees[]> {
    return this.http.get<NewFees[]>(`${environment.newApiUrl}/api/v1/product-detail/fees`, {
      params: {
        asin,
        marketplace,
        price: String(price)
      }
    })
  }

  // getOffers(asin: string, marketplace: string): Observable<{offers: any[]}>{
  //   return this.http.get<ProductDetail>(`${environment.newApiUrl}/api/v1/product-detail/offers`, {
  //     params: {
  //       marketplace,
  //       asin
  //     }
  //   }).pipe(retry(1))
  // }

  getSellers(sellerId: string[], marketplace: string): Observable<Seller[]> {
    return this.http.get<Seller[]>(`${environment.apiUrl}/SellerInformation`, {
      params: {
        marketplace,
        sellerId
      }
    }).pipe(retry(1))
  }

  getVariations(asins: string[], marketplace: string): Observable<ChildrenData[]> {
    return this.http.get<ChildrenData[]>(`${environment.newApiUrl}/api/v1/product-detail/variation`, {
      params: {
        marketplace,
        asin: asins
      }
    }).pipe(retry(1))
  }

  getEstimatedSales(rank: number, marketplace: Marketplace, asin: string, category: string): Observable<any> {
    return this.http.get(`${environment.newApiUrl}/api/v1/estimated-sales`, {
      params: {
        rank: String(rank),
        marketplace,
        asin,
        category
      }
    }).pipe(retry(1))
  }

  async isRestricted(data: { asin: string; domainEnd: string; condition: string; }): Promise<boolean | null> {
    if ((<any>window).chrome !== undefined) {
      const result: boolean | null = await new Promise((res, rej) => {
        data['type'] = 'restriction';
        (<any>window).chrome.runtime.sendMessage(environment.extensionId, data, function (response: { err: string; result: boolean },) {
          if (response === undefined) return rej()
          if (response.err) return res(null)
          res(response.result)
        })
      })
      return result
    } else {
      return null
    }
  }

  getDailyPicks(marketplace?: string, reload?:boolean) {
    
    if (!marketplace) {
      marketplace = "us"
    }
    //TODO: to request random 6 products
    return this.http.get<any>(`${environment.newApiUrl}/api/v1/product-winner`, {
      params: {
        marketplace,
        count: '6',
        reload: reload  ? 'true' : 'false'
      }
    })
  }

  updateScanArchive(scanIds: string[], archived: boolean) {
    return this.http.post(`${environment.newApiUrl}/api/v1/scan/${archived ? "archive" : "unarchive"}`, {
      "scanIds": scanIds
    })
  }

  getcurrencySymbol(currency: string) {
    let currencySymbol = '$';
    switch (currency) {
      case 'USD':
        currencySymbol = '$';
        break;
      case 'CAD':
        currencySymbol = 'CA$';
        break;
      case 'MXN':
        currencySymbol = 'MX$';
        break;
      case 'GBP':
        currencySymbol = '£';
        break;
      case 'EUR':
        currencySymbol = '€';
        break;
      case "MYR":
        currencySymbol = 'MYR';
        break;
      case "IDR":
        currencySymbol = 'IDR';
        break
      default:
        currencySymbol = '$';
        break;
    }
    return currencySymbol;
  }


  updateStripeName(name: string) {
    return this.http.post(`${environment.newApiUrl}/api/v1/user/update-stripe-bill-to`, {
      name
    })
  }

}
