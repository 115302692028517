import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DatabaseService } from '../../shared/services/database.service';
import { UserNotification } from 'app/shared/models/userNotification.model';
import { orderBy, find } from 'lodash';
import { environment } from 'environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { Router, RoutesRecognized, NavigationEnd, NavigationStart } from '@angular/router';
import { UserGeneral } from 'app/shared/models/user.model';
import * as Sentry from "@sentry/browser";
import packageJson from '../../../../package.json'
import { take, takeUntil } from 'rxjs/operators';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { HelpHeroService } from 'app/shared/services/helphero.service';
// import { AffiliateService } from 'app/shared/services/affiliate.service';
import { SubscriptionService } from 'app/shared/services/subscription.service';
import { DebugService } from 'app/shared/services/debug.service';
import { Debug } from 'app/shared/models/debug.model';
import { MatDialog } from '@angular/material';
import { ConfirmdialogComponent } from 'app/shared/components/confirmdialog/confirmdialog.component';
import { ClientJS } from 'clientjs'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  unreadNotifications: number;
  notificationsArray: UserNotification[] = []
  membership: string;
  @ViewChild('sidenav', { static: true })
  sidenav;
  @ViewChild('migrateTemplate', { static: true }) migrateTemplateRef: TemplateRef<any>
  userEmail: string;
  accountInfo: UserGeneral
  userStats
  // intercomHash: string;
  darkTheme: boolean;
  verifiedMarketplaces: string[] = [];
  firstInit = true;

  destory$ = new Subject();

  sessionId: string
  fid: string
  host: string

  showBanner = false;
  dayLeft = 0;

  constructor(
    private db: DatabaseService,
    private authService: AuthService,
    private router: Router,
    private snackbar: SnackbarService,
    private helpHeroService: HelpHeroService,
    private subscriptionService: SubscriptionService,
    private debugService: DebugService,
    private dialog: MatDialog,
    // private affiliateService: AffiliateService // Init affiliate service to prevent request during get scan
  ) { }

  async ngOnInit() {
    this.sessionId = localStorage.getItem('__sid')
    const client = new ClientJS()
    this.fid = client.getFingerprint()

    if (window != undefined) {
      this.host = window.location.host
    }

    // if (!this.sessionId) {
    //   try {
    //     const sessionId = await this.authService.createSession(this.host, this.fid).toPromise()

    //     sessionStorage.setItem('__sid', sessionId['sessionId'])


    //     this.sessionId = sessionStorage.getItem('__sid')
    //   } catch (e) {
    //     Sentry.captureException(e)
    //   }
    // }

    this.userStats = await this.db.userStats.pipe(take(1)).toPromise()
    // this.intercomHash = await this.db.intercomHash
    // this.verifiedMarketplaces = await this.db.verifiedMarketplaces.pipe(take(1)).toPromise()
    await this.getVerifiedMarketplace(true);

    this.db.getVerifiedSPMarketplaces().pipe(takeUntil(this.destory$)).subscribe(result => {
      if (!this.firstInit) {
        this.getVerifiedMarketplace();
      }
    });

    //TEMP disable auto logout
    this.authService.getSession().pipe(takeUntil(this.destory$)).subscribe(async result => {
      //show banner
      if (this.router.url.includes('/settings')) {
        this.showBanner = false
      } else {
        this.showBanner = !this.authService.isEmailVerified()
      }
      if (result.length < 1) {
        // localStorage.removeItem('__sid')
        // await this.authService.signOut();
        // window.localStorage.setItem('__so', 'session ended, please sign in again.')
        // window.location.href = '/session/signin';

      } else {
        const now = Date.now()

        const isExist = !!(find(result, r => r.key == this.sessionId))

        if (!isExist && result.length > 0) {
          //TODO: to check if the signin is new
          // localStorage.removeItem('__sid')
          // await this.authService.signOut();
          // window.localStorage.setItem('__so', 'session ended, please sign in again.')
          // window.location.href = '/session/signin';
        } else {

          const rootRef = find(result, r => r.key == this.sessionId).payload.ref

          rootRef.once('value').then((snapshot) => {
            const sid = snapshot.key
            const isCodeVerified = snapshot.child('codeVerified').val()

            //TODO: if code is not verified then redirect
            if (!isCodeVerified) {


              this.router.navigate(['/session/otp'])


            }
          })
        }
      }
    })

    this.router.events.pipe(takeUntil(this.destory$)).subscribe(async event => {

      if (event instanceof NavigationStart) {
        this.getVerifiedMarketplace();
      }
      if (event instanceof RoutesRecognized && event.url.startsWith('/scans/')) {
        this.sidenav.close();
      }
      if (event instanceof NavigationEnd) {


        if (this.authService.currentUser && !event.url.includes('session')) {
          // (<any>window).Intercom('update', this.intercom(this.accountInfo))
          this.helpHeroService.update(this.updateHelpHero())

          //show banner
          if (this.router.url.includes('/settings')) {
            this.showBanner = false
          } else {
            this.showBanner = !this.authService.isEmailVerified()
          }
        }

        // remove widget when outside dashboard
        if (!event.url.includes('dashboard') && window['videoask-widget']) {
          window['videoask-widget'].remove();
          window['videoask-widget'] = null;
        }

        try {

          if (!this.sessionId) {
            const sessionId = await this.authService.createSession(this.host, this.fid).toPromise()

            localStorage.setItem('__sid', sessionId['sessionId'])

            this.sessionId = localStorage.getItem('__sid')

          }

          await this.authService.updateSession(this.sessionId, this.host, this.fid).toPromise()

        } catch (e) {
          Sentry.captureException(e)
        }
      }
    });
    this.db.notificationsList.pipe(takeUntil(this.destory$)).subscribe(
      notifications => {
        this.notificationsArray = orderBy(notifications, ['date'], ['desc']);
        this.unreadNotifications = 0;
        notifications.forEach(notification => {
          if (notification.read !== undefined) {
            if (!notification.read) {
              this.unreadNotifications++;
            }
          }
        });
      }
    );

    try {
      console.log(`v${packageJson.version}`);

      this.userEmail = await this.db.getUserSettingsEmail().pipe(take(1)).toPromise()

      // Case: when user register via google and include coupon
      if (localStorage.getItem('authPromo')) {
        const promo = JSON.parse(localStorage.getItem('authPromo'));
        await this.authService.updateUserPromo(promo);
        localStorage.removeItem('authPromo');
      }

      if (window['C6Navbar']) {
        window['C6Navbar'].init({
          APP_KEY: environment.c6NavbarWidget.APP_KEY,
          APP_SECRET: environment.c6NavbarWidget.APP_SECRET,
          USER_ID: this.userEmail,
        });
      }

      this.accountInfo = await this.db.AccountInfo;

      this.membership = this.subscriptionService.planName

      this.helpHeroService.identify(this.updateHelpHero());

      // (<any>window).Intercom('boot', this.intercom(this.accountInfo));

      // Full Story
      (<any>window).FS.identify(this.authService.currentUserId, {
        displayName:
          this.accountInfo.firstName ||
          this.authService.currentUser.displayName ||
          this.userEmail,
        email: this.userEmail,
        plan: this.membership ? this.membership : 'free',
        email_verified: this.authService.currentUser.emailVerified
      });

      window['profitwell']('start', { user_id: this.subscriptionService.customerId });

      this.db.appVersion.subscribe(version => {
        if (environment.environment === 'local') return
        if (environment.environment === 'dev') return
        if (environment.environment === 'beta') return
        if (version !== packageJson.version) {
          this.snackbar.showSnackbar(
            'There is a new version available!',
            {
              link: 'https://app.scanunlimited.com',
              linkMsg: 'Refresh Now',
              refresh: true
            },
            3600000
          );
        }
      })
      this.firstInit = false;

    } catch (error) {
      this.router.navigate(['/session/error'])
    }
  }

  async requestVerification() {
    // this.authService.currentUser
    //   .sendEmailVerification()
    //   .then(_ => {

    //     alert("email sent")

    //   })
    ///session/email?mode=verifyEmail&oobCode=none
    //this.router.navigate(['/session/email?mode=verifyEmail&oobCode=none'])
    window.location.href = '/session/email?mode=verifyEmail&oobCode=none'
  }

  async getVerifiedMarketplace(showDialog = false) {
    const nowDate = new Date();
    // MWS API is shutdown at 30 sept 2022, so we force to use SP-API
    const isForceSPAPI = nowDate.getTime() > this.db.deadlineAmazon;

    if (this.router.url.includes('/settings')) {
      this.showBanner = false;
    }

    const marketplaces = await this.db.getVerifiedSPMarketplaces().pipe(take(1)).toPromise();
    const mwsMarketplaces = await this.db.getVerifiedMWSMarketplaces().pipe(take(1)).toPromise();

    if (marketplaces.length >= mwsMarketplaces.length) {
      // if already migrate all to SP-API
      this.verifiedMarketplaces = marketplaces;
      //this.showBanner = false;
    } else {
      this.verifiedMarketplaces = isForceSPAPI ? marketplaces : mwsMarketplaces;
      if (this.router.url.includes('/settings')) {
        // this.showBanner = false;
      } else {
        //this.showBanner = true;
        this.dayLeft = isForceSPAPI ? 0 : 30 - nowDate.getDate();
        if (showDialog) {
          this.showMigrationDialog();
        }
      }
    }

  }

  showMigrationDialog() {
    const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      width: '500px',
      data: {
        title: 'Migrate to SP API Amazon',
        message1: 'Amazon is updating their MWS API to SP-API, we need to update your connection to the marketplace for a smooth usage. Thanks!',
        customTemplateRef: this.migrateTemplateRef,
        actionButton: 'Migrate',
        color: 'primary'
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.router.navigate(['settings']);
      }
    });
  }

  notificationsClosed() {
    this.notificationsArray.forEach(notification => {
      this.db.readNotification(notification.key);
    });
  }

  async debug() {
    const text: Debug = {
      email: this.userEmail,
      uid: this.authService.currentUserId,
      plan: this.membership ? this.membership : 'free',
      email_verified: this.authService.currentUser.emailVerified,
      scansRun: this.userStats.scansRun.amount,
      lastScanTimestamp: `${new Date(this.userStats.scansRun.timestamp)}`,
      env: environment.environment,
      credentials: this.verifiedMarketplaces,
      url: this.router.url,
      settings: await this.db.scanSettings,
      recentRequests: this.authService.requests
    }
    if (this.router.url.includes('scans') && this.router.url.includes('-')) {
      text['scanMeta'] = await this.db.scanDetailMeta(this.router.url.split('/')[2]).pipe(take(1)).toPromise()
    }
    try {
      await this.debugService.sendDebugToFirebase(text)
      this.snackbar.showSnackbar('Debug data sent to support!')
    } catch (error) {
      this.snackbar.showSnackbar('Debug data failed to send!')
    }
  }

  updateHelpHero() {
    return {
      plan: this.membership ? this.membership : 'free',
      uploads: this.userStats.scansRun.amount,
      na: this.verifiedMarketplaces.indexOf('NA') !== -1 ? true : false,
      eu: this.verifiedMarketplaces.indexOf('EU') !== -1 ? true : false
    }
  }

  ngOnDestroy() {
    if (this.destory$) {
      this.destory$.next(true);
      this.destory$.unsubscribe();
    }
  }
}
