import { Injectable } from '@angular/core';
import initHelpHero from 'helphero';
import { AuthService } from './auth.service';
import { DatabaseService } from './database.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HelpHeroService {
  public helpHero

  constructor(
    private authService: AuthService
    ) {
      this.authService.authstate.subscribe(auth => {
        if(auth && !this.helpHero){
          this.helpHero = initHelpHero('XQj0VBWPVri');
        } else{
          this.close()
        }
      })
    }

  identify(data: {}){
    this.helpHero.identify(this.authService.currentUserId, {
      ...data
    });
  }

  update(data: {}){
    this.helpHero.update(data);
  }

  close(){
    this.helpHero.setOptions({showBeacon: false})
  }
}
