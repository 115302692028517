import { Injectable } from '@angular/core';
import { CurrencyService } from './currency.service';
import { find } from 'lodash';
import { Marketplace } from '../models/credentials-api/valid-marketplaces.model';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AffiliateService {

  constructor(private currencyService: CurrencyService, private http: HttpClient) {}

  affiliateLink(marketplace: Marketplace, asin: string){
    window.open(`https://www.amazon.${this.findDomain(marketplace)}/dp/${asin}/`, '_blank');
  }

  amazonStorefront(marketplace: Marketplace, sellerId: string){
    window.open(`https://www.amazon.${this.findDomain(marketplace)}/sp?seller=${sellerId}`, '_blank');
  }

  findDomain(marketplace: Marketplace): string {
    let domainEnd: string;
    Object.keys(this.currencyService.regions).forEach(region => {
      const marketplaceObj: any = find(this.currencyService.regions[region], {
        code: marketplace
      });
      if (marketplaceObj) {
        domainEnd = marketplaceObj.domain;
      }
    });

    return domainEnd
  }

}
