import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'app/shared/services/database.service';
import PromoData from 'assets/data/promos.json'
import { SubscriptionService } from 'app/shared/services/subscription.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { VideoComponent } from '../video/video.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {
  @ViewChild('myVideo', {static: true}) myVideo:TemplateRef<any>;

  promo: any;
  membership: string = 'plus'
  videoSrc:SafeResourceUrl

  constructor(
    private route: ActivatedRoute,
    private angularFireAuth: AngularFireAuth,
    private db: DatabaseService,
    public subService: SubscriptionService,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer:DomSanitizer
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(val => {
      this.promo = PromoData[val.id];
    })

    this.angularFireAuth.authState.pipe(take(1)).subscribe(user => {
      if (user && user.metadata) {
        this.initUser()
      }
    });
  }



  initUser() {

    this.db.getMembership().subscribe(m => {
      const price = m.price.toLowerCase()
      if (price.includes('plus')) {
        this.membership = 'plus'
      } else {
        this.membership = ''
      }

    })
  }

  upgradeMembership(uri?: string) {
    if (this.membership === 'plus' && uri !== '') {
      window.open(uri, '_blank')
    } else {
      this.router.navigateByUrl('/subscription')
    }
  }

  openVideo(id:string) {
    const uri = `https://player.vimeo.com/video/${this.promo.videoId}?h=13d85ec503&title=0&byline=0&portrait=0`
    this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${this.promo.videoId}?h=13d85ec503&title=0&byline=0&portrait=0`)
    this.dialog.open(this.myVideo)
  }

}
