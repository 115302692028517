import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, Injector } from '@angular/core';
import { Product } from 'app/shared/models/sis/product.model';
import { ProductDetailOptions } from '../../models/productDetailOptions.model';
import { SisService } from 'app/shared/services/sis.service';
import { ProductDetail } from 'app/shared/models/sis/product-detail.model';
import * as Sentry from "@sentry/browser";
import { FilterService } from 'app/scans/scan-detail/filter/filter.service';
import { AuthService } from 'app/shared/services/auth.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-detail-core',
  templateUrl: './product-detail-core.component.html',
  styleUrls: ['./product-detail-core.component.scss']
})
export class ProductDetailCoreComponent implements OnInit {
  @Input() dataInput: ProductDetailOptions;
  @Input() detailView: boolean;
  @Input() scanDetailDetectionRef: ChangeDetectorRef
  marketplace: string;
  scanId: string;
  id: string;
  data: Product[];
  error: boolean;
  selectedAsin: ProductDetail;
  productCost = 0;
  closingFee = 0;
  requestCurrency: string;
  vat: number;
  prepFee: number;
  percentAdditional: number;
  condition: string;
  loading: boolean;
  estSales:number;
  fulfillmentMethod: 'FBA' | 'MFN' = 'FBA'
  productPrice:number;

  @Output() back: EventEmitter<void> = new EventEmitter();
  public dialogData: ProductDetailOptions

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
    private sisService: SisService, 
    private filterService: FilterService, 
    public authService: AuthService) {
      if(this.router.url.includes('/product-folders/')){
        this.dialogData = this.injector.get(MAT_DIALOG_DATA)
        this.dataInput = this.dialogData
        this.detailView = true
      }
    }

  ngOnInit() {
    this.marketplace = this.dataInput.marketplace;
    if (this.dialogData && this.dialogData.scanId) {
      // only for product folder
      this.scanId = this.dataInput.scanId;
    } else {
      this.scanId = this.route.snapshot.params.id;
    }
    this.dataInput = {
      cost: 10,
      ...this.dataInput
    }


    this.id = this.dataInput.id.trim();
    
    if (this.dataInput.cost) {
      this.productCost = this.dataInput.cost;
    }

    if (this.dataInput.currency) {
      this.requestCurrency = this.dataInput.currency;
    }
    if (this.dataInput.vat) {
      this.vat = this.dataInput.vat;
    }
    if(this.dataInput.condition) {
      this.condition = this.dataInput.condition
    }
    if(this.dataInput.prepFee) {
      this.prepFee = this.dataInput.prepFee
    }
    if(this.dataInput.percentAdditional) {
      this.percentAdditional = this.dataInput.percentAdditional
    }
    if(this.dataInput.fulfillmentMethod){
      this.fulfillmentMethod = this.dataInput.fulfillmentMethod
    }
    if(this.dataInput.closingFee !== null) {
      this.closingFee = this.dataInput.closingFee || 0;
    }

    this.apiRequest();
  }

  async apiRequest() {
    this.error = false;
    this.loading = true;
    this.selectedAsin = null;
    this.data = [];
    try {
      if(this.detailView){
        this.selectedAsin = await this.sisService.getProductDetail(this.id, this.marketplace).toPromise();
        
        if(!this.selectedAsin) throw new Error(`Product detail response empty for id ${this.id}`);
        
        this.estSales = this.selectedAsin.monthlySold;
        // for now hazmat get from other API
        const { hazmat, price } = await this.sisService.getProductDetailv2(this.scanId, this.dataInput._id).toPromise();        
        this.productPrice = price
        
        this.selectedAsin.hazmat = hazmat;

        this.selectedAsin.rank = Number(this.selectedAsin.salesRank)
        if(this.dataInput.ipAlert){
          this.selectedAsin.ipAlert = this.dataInput.ipAlert
        }
        if(this.dataInput.category){
          this.selectedAsin.mwsCategory = this.dataInput.category
        }
      } else {
        let response = await this.sisService.getSearchArray(this.id, this.marketplace).toPromise()

        if (response === null || response.length === 0) {
          throw new Error('response was null')
        }
        const brandCheck = await this.filterService.checkIpAlert(response.map(response => response.brand))
        response = response.map(row => {
          const found = Object.keys(brandCheck).find(brand => brand === row.brand)
          row.ipAlert = found ? true : false
          return row
        })
        if(response.length === 1){
          await this.itemClicked(response[0])
        }
        this.data = response
      }
      this.loading = false;
    } catch (error) {
      if(error && error.name === "HttpErrorResponse" && error.error.message){
        error = new Error(error.error.message)
      }
      if(error && error.message === 'Malformed Request'){
        this.error = true
        this.loading = false
        return
      }
      if(error && error.message === 'response was null'){
        this.error = true
        this.loading = false
        this.data = []
        return
      }
      Sentry.captureException(error)
      this.error = true
      this.loading = false
    }
    if(this.scanDetailDetectionRef) this.scanDetailDetectionRef.detectChanges()
  }

  async itemClicked(item: Product) {
    try {
      this.loading = true
      this.selectedAsin = await this.sisService.getProductDetail(item.asin, this.marketplace).toPromise();
      
      this.productPrice = this.selectedAsin['buyBoxPrice'].current
      
      if(!this.selectedAsin) throw new Error('Product detail response empty');

      this.selectedAsin.hazmat = item.hazmat;
      this.selectedAsin.rank = Number(item.salesRank)
      this.selectedAsin.ipAlert = item.ipAlert
      this.selectedAsin.mwsCategory = item.category

      if (this.selectedAsin.monthlySold) {
        this.estSales = this.selectedAsin.monthlySold
      } else {
        this.estSales = -2
      }


      this.loading = false
    } catch (error) {
      Sentry.captureException(error)    
      this.error = true
      this.loading = false  
    }
  }

  goBack() {
    
    this.back.emit();
  }

  toResults(){
    if (sessionStorage.getItem('dp') && sessionStorage.getItem('dp') == '1') {
      sessionStorage.removeItem('dp')
      history.back()
    } else {
      this.selectedAsin = null
    }
  }
}
