import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { AdminComponent } from './layouts/admin/admin.component';
import { DailyPicksComponent } from './plus/daily-picks/daily-picks.component';
import { PromoComponent } from './plus/promo/promo.component';

const routes: Routes = [{
  path: '',
  redirectTo: 'dashboard',
  pathMatch: 'full',
}, {
  path: '',
  component: AdminComponent,
  children: [{
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'scans',
    loadChildren: () => import('./scans/scans.module').then(m => m.ScansModule)
  },
  {
    path: 'product-folders',
    loadChildren: () => import('./product-folders/product-folders.module').then(m => m.ProductFoldersModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ItemDetailModule)
  },
  {
    path: 'community-calls',
    loadChildren: () => import('./community-calls/community-calls.module').then(m => m.CommunityCallsModule)
  },
  {
    path: 'success-talks',
    loadChildren: () => import('./success-talks/success-talks.module').then(m => m.SuccessTalksModule)
  },
  {
    path: 'plus',
    children: [
      {
        path: 'zero-to-freedom',
        loadChildren: () => import('./plus/zero-to-freedom/zero-to-freedom.module').then(m => m.ZeroToFreedomModule)
      },
      {
        path: 'daily-picks',
        component: DailyPicksComponent
      },
      {
        path: 'promo/:id',
        component: PromoComponent,
      }

    ]
  },
  {
    path: 'training',
    loadChildren: () => import('./training/training.module').then(m => m.TrainingModule)
  }
  ]
}, {
  path: '',
  component: AuthComponent,
  children: [{
    path: 'session',
    loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
  }]
}, {
  path: '**',
  redirectTo: 'session/404'
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
