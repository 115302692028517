import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MarketplaceCurrency } from 'app/shared/models/marketplaceCurrencies.model';
import { SisService } from 'app/shared/services/sis.service';
import Chart, { ChartData, ChartDataSets, ChartLegendOptions, ChartTooltipOptions, LinearScale } from 'chart.js';
import { ChartApi } from './chart-api.model';

@Component({
  selector: 'app-keepa-chart',
  templateUrl: './keepa-chart.component.html',
  styleUrls: ['./keepa-chart.component.scss']
})
export class KeepaChartComponent implements OnInit {
  @Input() asin: string = '';
  @Input() marketplace: string = '';
  @Input() day: 30 | 90 | 365 = 30;
  @Input() currency: MarketplaceCurrency;
  @Input() scanDetailDetectionRef: ChangeDetectorRef;
  @Input() exchangeRate:number = 1;

  loading: boolean = true;
  currencySymbol = '$';

  // options for Chart
  scales: LinearScale = {};
  tooltips: ChartTooltipOptions;
  legend: ChartLegendOptions = {};
  options = {
    maintainAspectRatio: false,
  }

  data: ChartData; // active data
  dataObj: {
    30?: ChartData;
    90?: ChartData;
    365?: ChartData;
  } = {}

  // here
  @Input() set isLoad(activeTab: number) {
    this.setKeepaDay(activeTab);
    if (this.canCallNewData()) {
      this.getAndCreateChart();
    }
  }

  constructor(
    private db: SisService,
  ) { }

  ngOnInit() {
  }

  setKeepaDay(activeTab: number) {
    switch (activeTab) {
      case 0:
        this.day = 30;
        break;
      case 1:
        this.day = 90;
        break;
      case 2:
        this.day = 365;
        break;
      default:
        this.day = 30;
        break;
    }
  }

  canCallNewData() {
    if (this.dataObj[this.day]) {
      this.UpdateActiveData();
      return false;
    } else {
      return true;
    }
  }

  UpdateActiveData() {
    if (this.dataObj[this.day]) {
      this.createChartOption();
      this.data = this.dataObj[this.day];
    }
  }

  getAndCreateChart() {
    this.createChartOption();
    this.getData();
  }

  createChartOption() {
    this.currencySymbol = this.db.getcurrencySymbol(this.currency);

    this.scales.yAxes = [
      {
        ticks: {
          maxTicksLimit: 6,
          callback: (value, index, values) => {
            return `${this.currencySymbol} ${value}`;
          }
        },
      }
    ];

    this.scales.xAxes = [
      {
        ticks: {
          maxTicksLimit: 12, // this show how many label show in xAsis
        },
        type: 'time',
        time: {
          tooltipFormat: 'MMM DD h:mm a',
          unit: this.day === 365 ? 'month' : 'day', // force using selected format
          displayFormats: {
            day: 'MMM D',
            month: 'MMM', // this format for 365 days
          }
        }
      }
    ];

    this.tooltips = {
      enabled: false,
      intersect: false,
      mode: 'index',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label;

          if (label.indexOf('Sales Rank') > -1) {
            label = label.substring(0, label.indexOf('#')).trim();
          } else {
            label = label.split(' ')[0].trim();
          }

          let symbol = this.currencySymbol;
          if (label === 'Sales Rank') {
            symbol = '';
          }
          return `${label}: ${symbol}${tooltipItem.yLabel}`
        },
      }
    };
  }

  async getData() {
    try {
      this.loading = true;
      const result = await this.db.getKeepaChart(this.marketplace, this.asin, this.day).toPromise();

      let labels = [],
        datasets: ChartDataSets[] = [];

      if (result.stats) {
        for (const name in result.stats) {

          const row = result.stats[name] as ChartApi;

          if (row && row.data && row.data.length > 0) {
            if (labels.length === 0) {
              labels = row.data.map(v => (Number(v.x) * 1000));
            }
            const val = row.data[row.data.length - 1].y * this.exchangeRate
            const label = `${row.label} ${this.currencySymbol} ${val.toFixed(2)}`;
            datasets.push({
              label,
              data: row.data.map(v => { 
                v.x = Number(v.x) * 1000; 
                v.y = v.y * this.exchangeRate;
                return v; 
              }) || [],
              steppedLine: true,
              pointRadius: 0,
              fill: false,
              ...this.additionalDatasetConfig(name),
            });
          } // end if

        }  // end for
      }

      // Must in first array
      if (result.salesRank && result.salesRank.data && result.salesRank.data.length > 0) {

        if (labels.length === 0) {
          labels = result.salesRank.data.map(v => (Number(v.x) * 1000));
        }
        const sr = Number(result.salesRank.data[result.salesRank.data.length - 1].y)
        const label = `${result.salesRank.label} #${sr.toLocaleString('en-US')}`;
        datasets.unshift({
          yAxisID: 'salesRank',
          label,
          data: result.salesRank.data.map(v => { v.x = Number(v.x) * 1000; return v; }) || [],
          borderColor: '#a1c7a1',
          fill: false,
          steppedLine: true,
          pointRadius: 0,
          // hidden: result.stats ? true : false,
        });

        this.scales.yAxes.push({
          id: 'salesRank',
          type: 'linear',
          position: 'right',
          gridLines: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 6,
            callback: (value, index, values) => {
              return `#${value.toLocaleString('en-US')}`;
            }
          }
        });
      }
      this.data = {
        labels,
        datasets
      };
      this.dataObj[this.day] = {
        labels,
        datasets
      };

      this.loading = false;

      this.triggerToRender();
    } catch (error) {
      this.loading = false;
      this.triggerToRender();
    }
  }

  updateOption(chart: Chart) {
    try {
      if (chart) {
        chart.options.scales.xAxes[0].time.unit = this.scales.xAxes[0].time.unit;
        chart.update();
      }
    } catch (error) {

    }
  }

  triggerToRender() {
    if (this.scanDetailDetectionRef) {
      this.scanDetailDetectionRef.detectChanges();
    }
  }

  // here we preset configuration for chart eg color
  additionalDatasetConfig(name: string) {
    let obj = {};
    switch (name) {
      case 'amazon':
        obj = {
          borderColor: '#ff990066',
          backgroundColor: '#ff99002e',
          fill: true,
        }
        break;

      case 'new':
        obj = {
          borderColor: '#8888DD',
        }
        break;

      default:
        break;
    }
    return obj;
  }

}
