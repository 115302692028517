export enum PriceType {
    FREE = "FREE",
    UNLIMITED = "UNLIMITED",
    ANNUAL = "ANNUAL",
    UNLIMITEDPLUS = "UNLIMITED-PLUS",
    ANNUALPLUS = "ANNUAL-PLUS",
    TWODAYPASS = "2DAYPASS"
}

export interface Membership {
    customerId: string;
    // price: 'FREE' | '2DAYPASS' | 'UNLIMITED' | 'ANNUAL' | 'UNLIMITED-PLUS' | 'ANNUAL-PLUS';
    price: PriceType;
    cardLast4?: number;
    cardExpMonth?: number;
    cardExpYear?: number;
    cardBrand?: string;
    cancelOnPeriodEnd?: true
    renewDate?: number;
}

