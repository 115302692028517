import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface DialogData {
  url?: string;
}

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {
  url?: SafeResourceUrl = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if (!this.dialogData || this.dialogData === {}) return;

    if (this.dialogData.url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogData.url);
    }

  }

}
