import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { of, Observable, timer, BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Charge } from '../models/subscription/charge.model';
import * as Sentry from '@sentry/browser'
import { UserStats } from '../models/user.model';
import { Promo } from '../models/subscription/promo.model';
import { Membership, PriceType } from 'app/shared/models/subscription/membership.model';
// promo codes to be in memory only

interface ScansRun {
  timestamp: number;
  amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private _membership: Membership = {
    price: PriceType.FREE,
    customerId: ''
  }

  public userStatSubject: BehaviorSubject<UserStats> = new BehaviorSubject({
    scanned: 0,
    scansRun: {
      amount: 1,
      timestamp: 4088109622000
    }
  })

  get isActive(){
    if(this._membership.price === 'FREE'){
      return false
    } else {
      return true
    }
  }

  get membership() {
    return this._membership
  }

  get customerId() {
    return this._membership.customerId;
  }

  get planName(){
    return this._membership.price
  }

  get renewDate(){
    return this._membership.renewDate
  }

  get isSetToCancel(){
    return this._membership.cancelOnPeriodEnd
  }

  get is2DayPass(){
    return this._membership.price === PriceType.TWODAYPASS
  }

  get isUnlimited(){
    return this._membership.price === PriceType.UNLIMITED
  }

  get isAnnual(){
    return this._membership.price === PriceType.ANNUAL
  }

  get isUnlimitedPlus() {
    return this._membership.price === PriceType.UNLIMITEDPLUS
  }


  get isAnnualPlus() {
    return this._membership.price === PriceType.ANNUALPLUS
  }

  get isFree() {
    return this._membership.price === PriceType.FREE
  }

  get isPlus() {
    return this._membership.price === PriceType.UNLIMITEDPLUS || this._membership.price === PriceType.ANNUALPLUS
  }

  get cardDetails(){
    if(this._membership){
      const {cardBrand, cardExpYear, cardExpMonth, cardLast4} = this._membership
      return {
        cardBrand,
        cardExpMonth,
        cardExpYear,
        cardLast4
      }
    }
  }

  get canUpload(){
    const userStats = this.userStatSubject.getValue()
    if(!this._membership || !userStats){
      if(!this._membership) Sentry.captureException(new Error(`membership: ${this._membership}`))
      if(!userStats) Sentry.captureException(new Error(`scansRun: ${userStats}`))
      return false
    }

    // check if it has been a month since user's last scan
    const now = new Date().getTime()
    const isTimestampAllowed = userStats.scansRun.timestamp <= now - 2592000000
    if(isTimestampAllowed) return true

    // check if user is new to SU
    const isNewAllowed = userStats.scansRun.amount === 0
    if(isNewAllowed) return true

    // check if user has an active subscription
    if(this.isActive) return true

    // if all checks fail
    return false
  }

  constructor(
    private afdb: AngularFireDatabase, 
    private authService: AuthService,
    private http: HttpClient
  ) { 
    this.startMembershipStream()
    this.startUserScansStream()
  }

  private startMembershipStream(): void {
    timer(0, 5000)
      .pipe(
        switchMap(() => this.afdb
          .object(`/membership/${this.authService.currentUserId}/`)
          .snapshotChanges()
          .pipe(
            take(1),
            map(data => {
                const payload = data.payload.val()
                return payload as Membership
              }
            ),
            catchError(err => {
              //console.log(err)
              // probably useless because angularfire manages the connection retries
              Sentry.captureException(err)
              return of({
                customerId: '',
                price: 'FREE'
              }  as Membership)
            })
          )
        )
      )
      .subscribe((membership) => {
        if(membership) {
          this._membership = membership;
        }
      })
  }

  private startUserScansStream(): void {
    timer(0, 5000)
      .pipe(
        switchMap(() => this.afdb.object<UserStats>(`/users/${this.authService.currentUserId}/stats`).valueChanges()
        .pipe(
          take(1),
          catchError(err => {
            // probably useless because angularfire manages the connection retries
            Sentry.captureException(err)
            return of({
              scanned: 0,
              scansRun: {
                amount: 1,
                timestamp: 4088109622000
              }
            })
          })
        ))
      )
      .subscribe(userStats => {
        if(userStats) this.userStatSubject.next(userStats)
      })
  }

  createSubscription(price: Membership["price"], coupon?: string | null): Observable<string>{
    const options: Object = {
      params: {
        price
      },
      responseType: 'text'
    }
    if(coupon) options['params']['coupon'] = coupon

    const ep = `${location.protocol}//${location.host}`
    console.log(ep)
    options['params']['ep'] = ep

    return this.http.post<string>(`${environment.lambdaUrl}/payments/subscribe`, {}, options)
  }

  dryRun(price: Membership["price"]): Observable<string>{
    const options: Object = {
      params: {
        price,
        dryrun: true
      },
      responseType: 'text'
    }
    return this.http.post<string>(`${environment.lambdaUrl}/payments/subscribe`, {}, options)
  }

  dryRunExecute(price: Membership["price"], dryruntimestamp: string): Observable<string>{
    const options: Object = {
      params: {
        price,
        dryruntimestamp
      },
      responseType: 'text'
    }
    return this.http.post<string>(`${environment.lambdaUrl}/payments/subscribe`, {}, options)
  }

  updatePaymentMethod(): Observable<string>{
    const options: Object = {
      params: {
        price: this.planName,
        updatePaymentMethod: true
      },
      responseType: 'text'
    }

    return this.http.post<string>(`${environment.lambdaUrl}/payments/subscribe`, {}, options)
  }

  getStripeCharges(): Observable<Charge[]>{
    return this.afdb.list<Charge>(`/charges/${this.authService.currentUserId}`).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }

  updateStripeChargesTracked(key: string): Promise<void>{
    return this.afdb.object(`/charges/${this.authService.currentUserId}/${key}`).update({analytics: true})
  }

  getStripeInvoice(chargePushKey: string): Observable<{invoicePDF: string}>{
    return this.http.get<{invoicePDF: string}>(`${environment.apiUrl}/GetInvoicePDF`, {
      params: {
        charge_id: chargePushKey
      }
    })
  }

  get availablePromos(): Observable<any> {
    return this.afdb.list('promos').valueChanges();
  }

  get existingPromo(): Observable<any> {
    return this.afdb.object(`/users/${this.authService.currentUserId}/promo`).valueChanges()
  }

  removePromo(): Promise<void> {
    return this.afdb.object(`/users/${this.authService.currentUserId}/promo`).remove()
  }

  async addCancelReason(data: {reason: string, reasonText: string | null}): Promise<void> {
    const newData = {
      timestamp: new Date().getTime(),
      uid: this.authService.currentUserId,
      ...data
    }
    await this.afdb.list(`feedback`).push(newData)
  }

  validateCouponDate(promo: Promo = null) {
    if (!promo || !promo.startDate || !promo.endDate) return false;

    const start = promo.startDate;
    const end = promo.endDate;

    const nowDate = new Date().getTime();
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();

    return (nowDate >= startDate && nowDate <= endDate);
  }

  // GTM coupon code
  get GTMCouponCode() {
    return 'Q4CARBON6X';
  }

  validateGTMCouponCode(value: string) {
    return value === this.GTMCouponCode || value === 'Q4Carbon6';
  }

  // GTM event with coupon 'Q4Carbon6' it valid for 6-12 oct 2022
  isGTMValidateDate(promo: Promo = null) {
    let start = '2022-10-06 06:00:00 UTC-5';
    let end = '2022-10-12 23:59:00 UTC-5';

    if (promo && promo.startDate && promo.endDate) {
      start = promo.startDate;
      end = promo.endDate;
    }

    const nowDate = new Date().getTime();
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();

    return (nowDate >= startDate && nowDate <= endDate);
  }

  // if user register in GTM event, user can use the code for 1 year start from register date
  isGTMValidateForYear() {
    const user = this.authService.currentUser;

    if (user && user.metadata && user.metadata.creationTime) {
      const dateNow = new Date();
      const registerDate = new Date(user.metadata.creationTime);
      const nextYearRegisterDate = new Date(registerDate.setFullYear(registerDate.getFullYear() + 1));

      return dateNow.getTime() <= nextYearRegisterDate.getTime();
    }
    return false;
  }

}
