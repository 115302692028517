import { Component, ViewChild, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Offer } from 'app/shared/models/sis/product-detail.model';
import { SisService } from 'app/shared/services/sis.service';
import { Seller } from 'app/shared/models/sis/seller.model';
import * as Sentry from '@sentry/browser'
import { ThemeService } from 'app/shared/services/theme.service';
import { AffiliateService } from 'app/shared/services/affiliate.service';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';
import { FormBuilder } from '@angular/forms';
interface OfferTable extends Offer{
 name: string;
 rating: number;
 ratingCount: number;
 ratingLast30Days: number;
}
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  columnNames: string[] = [
    'Landed Price',
    'Condition',
    'Fulfillment Method',
    'Seller Name',
    'buybox',
    'Seller Rating',
    'Feedback Count'
  ];
  dataSource: MatTableDataSource<OfferTable>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() scanDetailDetectionRef: ChangeDetectorRef
  @Input() currency: string;
  @Input() exchangeRate:number = 1;
  @Input() marketplace: Marketplace;
  _offers: Offer[];
  loading = true;

  fulfillmentOptions = [
    {
      value: '',
      label: 'All'
    }, {
      value: 'amazon',
      label: 'Amazon'
    }, {
      value: 'fba',
      label: 'FBA'
    }, {
      value: 'mfn',
      label: 'MFN'
    },
  ];

  conditionOptions = [
    {
      value: '',
      label: 'All'
    },
  ];

  filterForm = this.fb.group({
    fulfillment: [''],
    condition: [''],
  });

  constructor(
    private sisService: SisService,
    private affiliateService: AffiliateService,
    private fb: FormBuilder,
    public themeService: ThemeService) { }

  @Input() set offers(offers: Offer[]) {
    if(offers){
      //Sort offers by percentage won(Higest to lowest and null be the last), buy box winner will be the top 1
      let buyBoxWinner = offers.filter(offer => offer.is.buyBox == true)[0];
      this._offers = offers.filter(offer => offer.is.buyBox != true).sort(
        (o1, o2) => {
          if(o1.percentageWon == undefined) return 1;
          if(o2.percentageWon == undefined) return -1;

          return o2.percentageWon - o1.percentageWon;
        }
      );
      // sometimes not found buybox is true
      if (buyBoxWinner) {
        this._offers.unshift(buyBoxWinner);
      }

      const data = this._offers.map(offer => {
        
        // Add all list condition to filter base from data
        if (offer.condition) {
          const option = this.conditionOptions.find(c => c.value === offer.condition);
          if (!option) {
            this.conditionOptions.push({
              value: offer.condition,
              label: offer.condition,
            });
          }
        }
        return offer
      }) as OfferTable[];

      this.dataSource = new MatTableDataSource<OfferTable>(data);
      this.dataSource.paginator = this.paginator;
      this.loading = false
    }
  }

  ngOnInit(): void {
    // console.log(this.dataSource.data)
    // let d = this.dataSource.data
    // d.forEach((offer, i) => {
    //   d[i].landedPrice = offer.landedPrice * this.exchangeRate
    // })
    // this.dataSource.data = d

    this.filterForm.valueChanges.subscribe(result => {

      const data = this._offers.filter(offer => {
        const filterConditions = [];
        // we have more than 1 filter so we need to store it in array and check it one by one
        for (let filter in result) {
          if (result[filter] === '') {
            filterConditions.push(true);
            continue;
          }

          if (filter === 'fulfillment') {
            // mfn dont have its attribute is base from fba and amazon
            filterConditions.push(result[filter] === 'mfn' ? (!offer.is.fba && !offer.is.amazon) : offer.is[result[filter]]);
          } else if (filter === 'condition') {
            filterConditions.push(offer.condition === result[filter]);
          }
        }
        return filterConditions.every(f => f);
      }) as OfferTable[];

      data.forEach((offer, i) => {
        data[i].landedPrice = offer.landedPrice * this.exchangeRate
      })

      this.dataSource.data = data;
    })
  }

  openAmazonStorefront(offer: Offer) {
    if(offer.sellerId && offer.sellerId.length){
      this.affiliateService.amazonStorefront(this.marketplace, offer.sellerId)
    }
  }
}
