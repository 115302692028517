import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  message: string;
  link: string;
  linkMessage: string;
  refresh: boolean;
  undo: boolean;

  constructor(
    private snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router
  ) {
    this.message = data.msg;
    if (data.link) {
      this.link = data.link.link;
      this.linkMessage = data.link.linkMsg;
      if(data.link.refresh) this.refresh = data.link.refresh
    }
    if(data.undo){
      this.undo = true
    }
  }

  close() {
    this.snackBarRef.dismiss();
  }

  closeWithAction(){
    this.snackBarRef.dismissWithAction()
  }

  navigate() {
    if(this.refresh){
      (<any>window).location.reload(true)
    } else {
      if(this.link.startsWith('http')){
        return window.open(this.link)
      }
      this.router.navigateByUrl(this.link);
    }
  }
}
