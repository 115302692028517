import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AdminComponent } from './layouts/admin/admin.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { NotificationsComponent } from './layouts/admin/notifications/notifications.component';
import { SidenavComponent } from './layouts/admin/sidenav/sidenav.component';
import { ToolbarComponent } from './layouts/admin/toolbar/toolbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, APP_NAME, APP_VERSION } from '@angular/fire/analytics';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSliderModule } from '@angular/material/slider';
import 'hammerjs';
import { UploadComponent } from './layouts/admin/sidenav/upload/upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FileUploadModule } from 'ng2-file-upload';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';
import { SharedModule } from './shared/shared.module';
import * as Sentry from "@sentry/browser";
import { AuthInterceptor } from './shared/services/auth.interceptor';
import packageJson from '../../package.json';
import { MatTableModule } from '@angular/material/table';
import { DiscordComponent } from './layouts/admin/sidenav/discord/discord.component';
import { DailyPicksComponent } from './plus/daily-picks/daily-picks.component';
import { PromoComponent } from './plus/promo/promo.component';
import { MatCardModule, MatPaginatorModule } from '@angular/material';
import { DailyCardComponent } from './plus/daily-picks/daily-card/daily-card.component';

if(environment.environment !== 'local'){
  Sentry.init({
    dsn: "https://0ca7a42b03744554b2ae4654d0d7194e@o477633.ingest.sentry.io/1422589",
    release: `client-webapp@${packageJson.version}`,
    environment: environment.environment,
    ignoreErrors: [
      'Non-Error exception captured',
      'Permission denied to access object',
      'permission_denied'
    ]
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      return window.location.reload();
    }

    if(error.message && error.message.includes('permission_denied')){
      return
    }
    
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AdminComponent,
    NotificationsComponent,
    SidenavComponent,
    ToolbarComponent,
    UploadComponent,
    DiscordComponent,
    PromoComponent,
    DailyCardComponent,
    DailyPicksComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatTabsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatBadgeModule,
    MatSliderModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatGridListModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    FileUploadModule,
    MatDialogModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatTableModule,
    FlexLayoutModule,
    MatCardModule,
    MatPaginatorModule,
  ],
  entryComponents: [UploadComponent, SnackbarComponent, DiscordComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: APP_VERSION, useValue: packageJson.version },
    { provide: APP_NAME, useValue: packageJson.name },
    UserTrackingService,
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
