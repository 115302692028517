import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { Debug } from '../models/debug.model';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  constructor(private db: AngularFireDatabase, private authService: AuthService) { }

  sendDebugToFirebase(debugData: Debug): Promise<void>{
    return this.db.object(`/debug/${this.authService.currentUserId}`).set(JSON.stringify(debugData))
  }

}
