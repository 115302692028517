import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import {
  GetScanResponse,
  GetScanRequest,
  Filters
} from 'app/shared/models/filter/get-scan.model';
import { environment } from 'environments/environment';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';
import { retryWhen, delay, take, tap, delayWhen, retry } from 'rxjs/operators';
import { PowerUp } from 'app/shared/models/filter/powerup.model';
import _ from 'lodash'
@Injectable({
  providedIn: 'root'
})
export class FilterService {
  constructor(private http: HttpClient) {}

  filterScan(
    scanId: string,
    options: GetScanRequest = {
      viewOptions: [],
      filters: {
        salesRank: {
          $gt: 0
        }
      },
      sortColumn: 'rank',
      sortDirection: 'asc',
      limit: 50,
      page: 0
    },
    showHidden = false,
    showPowerUpRows = false
  ): Observable<GetScanResponse> {
    const opt = _.cloneDeep(options)
    // smallAndLight boolean handling, change object value to direct boolean value
    if (opt && opt.filters && opt.filters.smallAndLight && typeof opt.filters.smallAndLight === 'object') {
      for (let k in opt.filters.smallAndLight as Object) {
        opt.filters.smallAndLight = k === 'true';
      }
    }
    return this.http
      .post<GetScanResponse>(`${environment.newApiUrl}/api/v1/scan/${scanId}`, opt, {
        params: {
          showHiddenRows: String(showHidden),
          showPowerUpRows: String(showPowerUpRows)
        }
      })
  }

  downloadScan(
    scanId: string,
    options: {
      sortColumn: string,
      sortDirection: 'ASC' | 'DESC',
      filters?: Filters,
      viewOptions?: string[]
    },
    showHidden = false,
    showPowerUpRows = false
  ): Observable<any> {
    return this.http

    .post(`${environment.apiUrl}/GetScan`, options, {
        params: {
          scanId,
          download: 'true',
          showHiddenRows: String(showHidden),
          showPowerUpRows: String(showPowerUpRows),
        }
      })
  }

  updateField(scanId: string, id: string, updates: any): Observable<any>{
    return this.http.post(`${environment.newApiUrl}/api/v1/scan/${scanId}/updateScan`, updates, {
        params: {
          oid: id
        }
      })
  }

  deleteRow(scanId: string, id: string): Observable<any>{
    return this.http.put(`${environment.newApiUrl}/api/v1/scan/${scanId}/delete`, null, {
      params: {
        oid: id
      }
    })
  }

  powerUp(marketplace: Marketplace, asin: string[]): Observable<PowerUp[]>{
    return this.http.get<PowerUp[]>(`${environment.lambdaUrl}/powerup`, {
      params: {
        marketplace,
        asin
      }
    }).pipe(
      tap((response) => {
        // Client sent requests too early
        // @ts-ignore
        if (response.status === 425) {
          throw response;
        }
      }),
      retry(5)
      // retryWhen(errors =>
      //   errors.pipe(
      //     delayWhen((response) => {
      //       const waitingTime = parseInt(response.headers.get('x-powerup-throttle'));
      //       return timer(waitingTime);
      //     })
      //   )
      // )
    )
  }

  checkIpAlert(brands: string[]): Promise<IpAlert>{
    return this.http.get<IpAlert>(`${environment.newApiUrl}/api/v1/CheckBrands`, {
      params: {
        brand: brands
      }
    }).toPromise()
  }
}
