// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environment: 'dev',
  production: false,
  stripeKey: 'pk_test_LKtROGFuBemGuytUmYSsbat0',
  lambdaUrl: 'https://dev-api.scanunlimited.com',
  apiUrl: 'https://us-central1-scanunlimited-staging.cloudfunctions.net',
  newApiUrl: 'https://staging-api.scanunlimited.com',
  currencyUrl: 'https://dev-api.scanunlimited.com',
  firebaseConfig: {
    apiKey: "AIzaSyAKi_aho7qvL9Mp1na7hVCS5jfK116hYhY",
    authDomain: "scanunlimited-staging.firebaseapp.com",
    databaseURL: "https://scanunlimited-staging.firebaseio.com",
    projectId: "scanunlimited-staging",
    storageBucket: "scanunlimited-staging.appspot.com",
    messagingSenderId: "404147092871",
    appId: "1:404147092871:web:3ea93a3b2470759e79e722",
    measurementId: "G-JNEFNLDDHT"
  },
  extensionId: 'pdmefbdbleblmnmilfliomakbfpahlje',
  intercomAppId: 'brqkndzk',
  youtubeKey: 'AIzaSyBt7ZRZFNFrhuaEWvPGqn3xJpQ7NH9WzJ8',
  c6NavbarWidget: {
    APP_KEY: '7kW8o6aAatWxE0KFzL2YDAMKTpaJZd7z',
    APP_SECRET: 'test123',
  },
  videoask: {
    "kind": "widget",
    "url": "https://www.videoask.com/fsqi2gisi",
    "options": {
      "widgetType": "VideoThumbnailWindow",
      // "text": "Schedule your fast track call today!",
      "backgroundColor": "#00c853",
      "position": "bottom-right",
      "dismissible": true
    }
  },
  captchaSiteKey: '6LcEB-YkAAAAAAlYwi1iqTNyAiDQrjNeUgHVtaeE'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
