import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-daily-card',
  templateUrl: './daily-card.component.html',
  styleUrls: ['./daily-card.component.scss']
})

export class DailyCardComponent implements OnInit {
  @Input() data?:any

  @Output() favorite:EventEmitter<any> = new EventEmitter<any>()

  product:any = {
    name: 'Amazing Spider-Man 2, Bandai Spirits S.H.Figuarts Action Figure',
    brand: 'LEGO',
    estimatedSales: 1450,
    offers: 17,
    buyBoxPrice: 18.99,
    image: 'assets/images/static/spidey.png',
    asin: 'ABX12345',
    amazonUrl: '',
    favorited: false,
  }
  constructor(
    private route:Router
  ) { }

  ngOnInit() {
    if (this.data) {
      this.product = this.data
    }
  }

  addFavorite() {
   //this.product.favorited = !this.product.favorited
    this.favorite.emit(this.product)
  }

  details() {
    sessionStorage.setItem('dp', '1')
    this.route.navigateByUrl(`/product-detail/US/${this.product.asin}/new`)
  }
  
  viewAmazon() {
    window.open(`https://www.amazon.com/dp/${this.product.asin}/`, '_blank')
  }
}
