import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { DatabaseService } from 'app/shared/services/database.service';
import { SisService } from 'app/shared/services/sis.service';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { ThemeService } from 'app/shared/services/theme.service';
import { VideoComponent } from '../video/video.component';

interface DailyProduct {
  asin: string
  brand: string
  buyBoxPrice: number
  category: string
  estimatedSales: number
  image: string
  marketplace: string
  name: string
  offers: number
  salesRank: number
  createdAt: number
  favorited: boolean
}

@Component({
  selector: 'app-daily-picks',
  templateUrl: './daily-picks.component.html',
  styleUrls: ['./daily-picks.component.scss']
})


export class DailyPicksComponent implements OnInit {

  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild('myVideo', {static: true}) myVideo:TemplateRef<any>;

  products: DailyProduct[];
  dataSource: MatTableDataSource<DailyProduct>;

  today: string;
  favorites: DailyProduct[]
  displayedColumns: string[] = ['fav', 'name', 'brand','category', 'estimatedSales', 'salesRank', 'offers', 'buyBoxPrice', 'asin', 'action']
  currencySymbol: string = `$`

  snackbar: MatSnackBar;
  membership:string = ''

  constructor(
    private sis: SisService,
    private db: DatabaseService,
    private auth: AuthService,
    private route: Router,
    private snackbarSvc: SnackbarService,
    private angularFireAuth: AngularFireAuth,
    private themeService:ThemeService,
    private dialog:MatDialog
  ) { }

  async ngOnInit() {

    this.angularFireAuth.authState.pipe(take(1)).subscribe(user => {
      if (user && user.metadata) {
        this.initUser()
      }
    });
   
  }

  gotoUpgradePlus() {
    this.route.navigate(['/subscription'])
  }

  copyToClipboard(copyItem: string, copyItemTitle: string, event?: any) {
    event.stopPropagation();
    event.preventDefault();
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyItem;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

  }



  async initialize() {
    const picks = await this.sis.getDailyPicks('us', false).toPromise().then(r => r)
    this.products = picks
    this.db.getDailyPicks().subscribe(data => {
     
      this.favorites = data
      //this.favorites = []
      this.dataSource = new MatTableDataSource<DailyProduct>()
  
      this.dataSource.paginator = this.paginator

      this.dataSource.data = this.favorites
    
      this.checkExistingsProducts()
    })
  }

  initUser() {
    
    this.db.getMembership().subscribe(m => {
      const price = m.price.toLowerCase()
      if (price.includes('plus')) {
        this.membership = 'plus'
        this.today= this.generateToday()
        this.initialize()
      } else {
        this.membership = 'nonplus'
      }

    })
  }


  checkExistingsProducts() {
    this.products.forEach(product => {
      product.favorited = false
      for (let x = 0; x < this.favorites.length; x++) {
        if (this.favorites[x].asin === product.asin) {
          product.favorited = true
          break
        }
      }
    })
  }

  viewProduct(asin: string) {
    sessionStorage.setItem('dp','1')
    this.route.navigateByUrl(`/product-detail/US/${asin}/new`)
  }

  generateToday() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let d, m, y;

    const now = new Date()
    d = now.getDate()

    if (d < 3 || d > 20) {
      switch (d % 10) {
        case 3:
          d += 'rd'
          break;
        case 2:
          d += 'nd'
          break
        case 1:
          d += 'st'
          break;
        default:
          d += 'th'
          break
      }
    } else {
      d += 'th'
    }

    m = months[now.getMonth()]
    y = now.getFullYear()
    return `${d} ${m}, ${y}`
  }

  addRemoveFavorite(item: any, ask?: boolean) {

    let id = this.productExists(item)

    if (id < 0) {
      item.favorited = true
      this.favorites = [item, ...this.favorites]
      this.snackbarSvc.showSnackbar(`Product has been added to your favorites`, null, 2000)

    } else {
      if (item.favorited) {
        if (ask) {
          const a = confirm('are you sure you want to remove this?')
          if (a) {
            item.favorited = false
            this.favorites.splice(id, 1)
            this.snackbarSvc.showSnackbar(`Product has been removed to your favorites`, null, 2000)
          }
        } else {
          item.favorited = false
          this.favorites.splice(id, 1)
          this.snackbarSvc.showSnackbar(`Product has been removed to your favorites`, null, 2000)
        }
      }
    }
    this.db.updateDailyPicks(this.favorites)
    //this.dataSource.data = this.favorites
  }

  getProductIndex(item: any) {
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].asin === item.asin) {
        return i
      }
    }
  }

  productExists(item: any) {
    if (this.favorites) {
      for (let i = 0; i < this.favorites.length; i++) {
        const product = this.favorites[i]
        if (product.asin === item.asin) {
          return i
        }
      }
    }
    return -1
  }

  openVideo(id?:string) {
    this.dialog.open(this.myVideo)
  }

}
