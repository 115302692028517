import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[debounce]'
})
export class DebounceDirective implements OnInit {
  @Output() debounceClick = new EventEmitter()
  private clickSubject = new Subject<MouseEvent>()

  constructor() { }

  ngOnInit(){
    this.clickSubject.pipe(debounceTime(500))
      .subscribe(async event => {
        this.debounceClick.emit(event)
      })
  }

  @HostListener('click', ['$event'])
  click(event: MouseEvent){
    this.clickSubject.next(event)
  }

}
