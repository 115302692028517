import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  actionButtonLabel = 'Dismiss';

  constructor(private snackBar: MatSnackBar) {}

  /**
   * Displays snackbar with message to user
   *
   * @param {string} msg
   * @memberof SnackbarService
   */
  showSnackbar(msg: string, link?: { link?: string; linkMsg?: string, refresh?: boolean }, time?: number) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: time ? time : 5000,
      data: { msg, link }
    });
  }

  showUndoSnackbar(msg: string, time?: number) {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: time ? time : 5000,
      data: { msg, undo: true }
    })
  }
}
