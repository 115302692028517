import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChildrenData } from 'app/shared/models/sis/product-detail.model';
import * as Sentry from '@sentry/browser'
import { SisService } from 'app/shared/services/sis.service';
import { Subject } from 'rxjs/internal/Subject';
import { PageEvent } from '@angular/material/paginator';
import { tap, debounce } from 'rxjs/internal/operators';
import { AffiliateService } from 'app/shared/services/affiliate.service';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';

@Component({
  selector: 'app-variations',
  templateUrl: './variations.component.html',
  styleUrls: ['./variations.component.scss']
})
export class VariationsComponent implements OnInit {
  columnNames: string[] = ['image', 'title', 'rank', 'category', 'color', 'size'];
  dataSource: MatTableDataSource<ChildrenData>;
  @Input() childrenAsins: string[];
  @Input() marketplace: Marketplace;
  @Input() scanDetailDetectionRef: ChangeDetectorRef
  listLength = 0
  debounceEvents: Subject<PageEvent> = new Subject();
  loading = true;
  cache: {[x: number]: ChildrenData[]} = {}

  constructor(
    private affiliateService: AffiliateService,
    private sisService: SisService) {}

  async ngOnInit() {
    try {
      // count asins
      this.listLength = this.childrenAsins.length;

      // init table
      this.dataSource = new MatTableDataSource<ChildrenData>();

      // get variation data for page 1
      if(this.childrenAsins && this.childrenAsins.length > 0){
        this.dataSource.data = await this.sisService.getVariations(this.childrenAsins.slice(0,5), this.marketplace).toPromise()
        this.cache[0] = this.dataSource.data
      }
      this.loading = false
    } catch (error) {
      Sentry.captureException(error)
      this.loading = false
    }

    if(this.scanDetailDetectionRef) this.scanDetailDetectionRef.detectChanges()

    this.debounceEvents
    .pipe(
      tap(() => this.loading = true),
      debounce((event) => {
        return new Promise((res) => {
          if(this.cache[event.pageIndex]){
            res(event)
          } else {
            setTimeout(() => res(event), 1000)
          }
        })
      })
    )
    .subscribe(async (event) => {
      try {
        if(this.cache[event.pageIndex]){
          this.dataSource.data = this.cache[event.pageIndex]
        } else {
          let asinsIndexStart = event.pageIndex * 5;
          let asinsIndexEnd = asinsIndexStart + 5;

          this.dataSource.data = await this.sisService.getVariations(this.childrenAsins.slice(asinsIndexStart, asinsIndexEnd), this.marketplace).toPromise()
          this.cache[event.pageIndex] = this.dataSource.data
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        Sentry.captureException(error)
      }
      if(this.scanDetailDetectionRef) this.scanDetailDetectionRef.detectChanges()
    });
  }

  amazonLink(row: ChildrenData) {
    this.affiliateService.affiliateLink(this.marketplace, row.asin)
  }

}
