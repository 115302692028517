import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Chart, ChartData, ChartLegendOptions, ChartOptions, ChartScales, ChartTooltipOptions, ChartType, LinearScale, LogarithmicScale, TimeScale } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  @Output() updateOption = new EventEmitter<Chart>();

  @Input() type: ChartType = 'line';
  @Input() options: ChartOptions = {};
  @Input() scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = {};
  @Input() tooltips: ChartTooltipOptions = {};
  @Input() legend: ChartLegendOptions = {};
  @Input() width: string;
  @Input() height: string;
  @Input() set data(d: ChartData) {
    if (!d) return;
    if (this.chart) {
      this.updateChart(d);
    } else {
      this.drawChart(d);
    }
  };

  chart: Chart;

  constructor(
  ) { }

  ngOnInit() {
  }


  drawChart(data: ChartData) {
    this.destroyChart();
    this.chart = new Chart(this.chartRef.nativeElement.getContext('2d'), {
      type: this.type,
      data: { ...data },
      options: {
        elements: {
          line: {
            tension: 0 // disables bezier curves
          }
        },
        legend: {
          position: 'right',
          ...this.legend,
        },
        scales: {
          ...this.scales
        },
        tooltips: {
          ...this.tooltips
          // callbacks: {
          //   label: (tooltipItem, data) => {
          //     const label = data.datasets[tooltipItem.datasetIndex].label;
          //     return `${label}: $ ${tooltipItem.yLabel}`
          //   },
          // }
        },
        ...this.options
      }
    });
  }

  updateChart(data: ChartData) {
    try {
      this.updateOption.emit(this.chart);
      this.chart.data = data;
      this.chart.update();
    } catch (error) {
      console.log(error)
    }
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

}
