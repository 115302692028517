export class FileRead {

    file: File;
    name: string;
    contentType: string;
    size: number;

    constructor(file: File, contentType: string, name: string, size: number) {
        this.file = file;
        this.contentType = contentType;
        this.name = name;
        this.size = size;
    }

}
