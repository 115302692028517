import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmdialogComponent } from './components/confirmdialog/confirmdialog.component';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '../../../node_modules/@angular/material/menu';
import { ProductDetailCoreComponent } from './components/product-detail-core/product-detail-core.component';
import { ProductDetailAsinComponent } from './components/product-detail-core/product-detail-asin/product-detail-asin.component';
import { CalculatorComponent } from './components/product-detail-core/product-detail-asin/calculator/calculator.component';
import { OffersComponent } from './components/product-detail-core/product-detail-asin/offers/offers.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { VariationsComponent } from './components/product-detail-core/product-detail-asin/variations/variations.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SecurePipe } from './pipes/secure.pipe';
import { MatSelectModule } from '@angular/material/select';
import { CancellationDialogComponent } from './components/cancellation-dialog/cancellation-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { TextDialogComponent } from './components/text-dialog/text-dialog.component';
import { DebounceDirective } from './directives/debounce.directive';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { KeepaChartComponent } from './components/product-detail-core/product-detail-asin/keepa-chart/keepa-chart.component';
import { ChartComponent } from './components/chart/chart.component';
@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MatDividerModule,
    MatPaginatorModule,
    MatTableModule,
    MatSliderModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatStepperModule,
    ReactiveFormsModule,
    // GoogleChartsModule
  ],
  declarations: [
    ConfirmdialogComponent,
    ProductDetailCoreComponent,
    ProductDetailAsinComponent,
    CalculatorComponent,
    OffersComponent,
    VariationsComponent,
    ClickOutsideDirective,
    SnackbarComponent,
    SecurePipe,
    CancellationDialogComponent,
    TextDialogComponent,
    DebounceDirective,
    VideoDialogComponent,
    KeepaChartComponent,
    ChartComponent
  ],
  exports: [
    ProductDetailCoreComponent,
    ProductDetailAsinComponent,
    CalculatorComponent,
    OffersComponent,
    ClickOutsideDirective,
    SnackbarComponent,
    SecurePipe,
    DebounceDirective,
    VideoDialogComponent,
    ChartComponent
  ],
  entryComponents: [ConfirmdialogComponent, CancellationDialogComponent, TextDialogComponent, ProductDetailCoreComponent, VideoDialogComponent],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    CurrencyPipe
  ]
})
export class SharedModule {}
