import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable ,  from } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        let {urlWithParams, body, method} = req
        this.authService.requests = [...this.authService.requests.slice(0,9), {urlWithParams, body, method, timestamp: new Date()}]
        
        if (req.url.includes('youtube.googleapis.com')) {
            // dont send token beacuse already use key api
            return next.handle(req);
        }
        // for https://bi.carbon6.io/api/customers/signup
        if (req.url.includes('carbon6')) {
            return next.handle(req);
        }

        return from(this.authService.currentUser.getIdToken()).pipe(switchMap((token) => {
            const reqCopy = req.clone({headers: req.headers.append('Authorization', `Bearer ${token}`)})
            return next.handle(reqCopy)
        }))
    }
}