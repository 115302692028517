import { Injectable } from '@angular/core';
import { Currency, FirebaseCurrency } from '../models/currency.model';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  regions = {
    NA: [
      {
        code: 'US',
        name: 'US - United States',
        currency: 'USD',
        domain: 'com'
      },
      { code: 'MX', name: 'MX - Mexico', currency: 'MXN', domain: 'com.mx' },
      { code: 'CA', name: 'CA - Canada', currency: 'CAD', domain: 'ca' }
    ],
    EU: [
      {
        code: 'UK',
        name: 'UK - United Kingdom',
        currency: 'GBP',
        domain: 'co.uk'
      },
      { code: 'FR', name: 'FR - France', currency: 'EUR', domain: 'fr' },
      { code: 'IT', name: 'IT - Italy', currency: 'EUR', domain: 'it' },
      { code: 'DE', name: 'DE - Germany', currency: 'EUR', domain: 'de' },
      { code: 'ES', name: 'ES - Spain', currency: 'EUR', domain: 'es' }
    ],
    BR: [
      { code: 'BR', name: 'BR - Brazil', currency: 'BRL', domain: 'com.br' }
    ],
    IN: [{ code: 'IN', name: 'IN - India', currency: 'INR', domain: 'in' }],
    CN: [{ code: 'CN', name: 'CN - China', currency: 'CNY', domain: 'cn' }],
    FE: [
      { code: 'AU', name: 'AU - Australia', currency: 'AUD', domain: 'com.au' },
      { code: 'JP', name: 'JP - Japan', currency: 'JPY', domain: 'co.jp' }
    ]
  };

  constructor(private authService: AuthService, private afdb: AngularFireDatabase, private http: HttpClient) { }

   /**
   * get currency rate as per 1 USD
   *
   * @param {string} currency
   * @returns {Promise<any>}
   * @memberof DatabaseService
   */
  exchange(
    currency: string,
    from: { marketplace?: string; currency?: string }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let fromCurrency = 'USD';
      if (from.marketplace !== undefined) {
        Object.keys(this.regions).forEach(region => {
          const marketplaceObj = _.find(this.regions[region], {
            code: from.marketplace
          });
          if (marketplaceObj) {
            fromCurrency = marketplaceObj['currency'];
          }
        });
      }
      if (from.currency !== undefined) {
        fromCurrency = from.currency;
      }
      if (fromCurrency !== undefined || fromCurrency !== null) {
        this.currencyConversion({
          to: currency,
          from: fromCurrency
        }).subscribe(
          data => {
            resolve(data.chart[0]);
          },
          error => {
            reject();
          }
        );
      }
    });
  }

  get currencies(): Observable<any> {
    return this.afdb
      .list(`users/${this.authService.currentUserId}/settings/currency`)
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }

  addCurrency(currency: Currency): void {
    this.afdb
      .list(`users/${this.authService.currentUserId}/settings/currency`)
      .push(currency);
  }

  deleteCurrency(currency: Currency): void {
    this.afdb
      .object(
        `users/${this.authService.currentUserId}/settings/currency/${
          currency.key
        }`
      )
      .remove();
  }

  async convertCurrency(currency:Currency) {
    const to = (await this.afdb.database.ref(`currency/${currency.to}`).once('value')).val()
    const from = (await this.afdb.database.ref(`currency/${currency.from}`).once('value')).val()
    
    const res = {
      value: to.rate/from.rate
    }
    return res
  }

  currencyConversion(currency: Currency): Observable<any> {

    if(currency.from === currency.to) return of({change: 0, chart: [1,1,1,1,1,1,1]})
    return this.http.get(`${environment.currencyUrl}/currency`,
      {
        params: {
          from: currency.from,
          to: currency.to
        }
      }
    );
  }

  getAllCurrencies(): Observable<FirebaseCurrency[]> {
    return this.afdb.list(`currency`).snapshotChanges().pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        ),
        take(1)
      ) as Observable<FirebaseCurrency[]>
  }
}
