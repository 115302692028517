import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  constructor() { }

  login(email: string) {
    if (!email) return;
    if (!window['MktoForms2']) return;
    window['MktoForms2'].whenReady((form: any) => {

      form.addHiddenFields({ Email: email });
      form.submit();

      form.onSuccess(() => {
        // to prevent reload
        return false;
      });
    })
  }

  submitEvent(key: string, data: any) {
    if (!window['Munchkin']) return;
    if (typeof data === "object") {
      data = JSON.stringify(data);
    }

    window['Munchkin'].munchkinFunction('visitWebPage', {
      /* '@su:' prefix to indicate that the activities comes from scanunlimited */
      url: `@su:${key}`,
      params: data
    });
  }

}
