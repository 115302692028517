import {
  UserGeneral,
  UserScanSettings
} from './../models/user.model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AngularFireDatabase
} from '@angular/fire/database';
import { UserNotification } from 'app/shared/models/userNotification.model';
import * as _ from 'lodash';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { HttpClient } from '@angular/common/http';
import { DownloadMeta } from '../models/download-meta.model';
import { environment } from 'environments/environment';
import { Membership, PriceType } from 'app/shared/models/subscription/membership.model';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(
    private afdb: AngularFireDatabase,
    private authService: AuthService,
    private http: HttpClient
  ) { }


  getScanList(): Observable<any[]> {
    return (this.afdb
      .list(`/scans/${this.authService.currentUserId}/`)
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
        ),
        map(changes => {
          return _.orderBy(changes, ['timestamp'], ['desc']);
        }),
        map((changes: DownloadMeta[]) => {
          return changes.filter(row => row.canceled !== true && row.uploaded === true);
        })
      ));
  }

  setScanMetaFulfillmentMethod(scanId: string, fulfillment: 'FBA' | 'MFN'): Promise<void> {
    return this.afdb.object(`/scans/${this.authService.currentUserId}/${scanId}`).update({ fulfillmentMethod: fulfillment })
  }

  setScanMetaCommonName(scanId: string, commonName: string): Promise<void> {
    return this.afdb.object(`/scans/${this.authService.currentUserId}/${scanId}`).update({ commonName })
  }

  updateScanMeta(scanId: string, data: {}) {
    return this.afdb.object(`/scans/${this.authService.currentUserId}/${scanId}`).update(data)
  }

  deleteScanMeta(scanId: string) {
    return this.afdb.object(`/scans/${this.authService.currentUserId}/${scanId}`).remove();
  }

  listenForDownloadLink(scanId: string): Observable<any> {
    return this.afdb.object(`/scans/${this.authService.currentUserId}/${scanId}/downloadURL`).valueChanges()
  }

  clearDownloadLink(scanId: string): Promise<any> {
    return this.afdb.object(`/scans/${this.authService.currentUserId}/${scanId}/downloadURL`).remove()
  }

  get userStats(): Observable<any> {
    return this.afdb
      .object<any>(`/users/${this.authService.currentUserId}/stats`)
      .valueChanges();
  }

  async cancelScans(pushKeyArray: string[]): Promise<void> {
    // const notifications = await this.afdb.list(`/notifications/${this.authService.currentUserId}/`).snapshotChanges().pipe(take(1)).toPromise()
    // const promiseArr = []
    // for (const pushKey of pushKeyArray) {
    //   promiseArr.push(this.http.put(`${environment.newApiUrl}/api/v1/scan/${pushKey.trim()}/cancel`, {}).pipe(retry(2)).toPromise())

    //   const notification = notifications.find(notificationObj => {
    //     const notification = notificationObj.payload.val();
    //     if (notification['link']) {
    //       const scanKey = notification['link'].split('/').pop();
    //       if (scanKey === pushKey) {
    //         return true
    //       }
    //     }
    //   });

    //   if(notification){
    //     promiseArr.push(this.afdb.object(`/notifications/${this.authService.currentUserId}/${notification['key']}`).remove())
    //   }
    // }
    // return <unknown>Promise.all(promiseArr) as Promise<void>
  }

  scanDetailMeta(pushKey: string): Observable<any> {
    return (this.afdb
      .object(`/scans/${this.authService.currentUserId}/${pushKey}/`)
      .valueChanges()
      .pipe(take(1)));
  }


  refreshScan(scanData: DownloadMeta): Promise<any> {
    delete scanData['archived'];
    delete scanData['error'];
    if (scanData.key) {
      scanData.key = null;
    }
    scanData.timestamp = null;
    scanData.powerUpCount = 0
    const pushId = this.afdb.createPushId();
    return this.afdb
      .list(`scans/${this.authService.currentUserId}/`)
      .set(pushId, scanData)
      .then(data => {
        return this.afdb
          .object(`scans/${this.authService.currentUserId}/${pushId}`)
          .update({ uploaded: true });
      });
  }

  /**
   * Get list of notifications
   *
   * @readonly
   * @type {Observable<Notification[]>}
   * @memberof DatabaseService
   */
  get notificationsList(): Observable<UserNotification[]> {
    return this.afdb
      .list<UserNotification>(
        `/notifications/${this.authService.currentUserId}/`,
        ref => {
          return ref.limitToLast(20);
        }
      )
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }
  // this temp solution for progress only show 99% if alreay solve remove this
  getNotifictions(): Observable<UserNotification[]> {
    return this.afdb
      .list<UserNotification>(
        `/notifications/${this.authService.currentUserId}/`
      )
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }


  /**
   * update user account info
   *
   * @param {UserGeneral} userGeneral
   * @returns {firebase.Promise<void>}
   * @memberof DatabaseService
   */
  saveAccountInfo(userGeneral: UserGeneral): Promise<void> {
    return this.afdb
      .object(`/users/${this.authService.currentUserId}/general`)
      .update(userGeneral);
  }

  /**
   * retreive account info for user
   *
   * @readonly
   * @type {Promise <UserGeneral>}
   * @memberof DatabaseService
   */
  get AccountInfo(): Promise<UserGeneral> {
    return this.afdb
      .object<UserGeneral>(`/users/${this.authService.currentUserId}/general`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  get appVersion(): Observable<string> {
    return this.afdb.object<string>('updates/version')
      .valueChanges()
  }

  /**
   * retreive user scan settings
   *
   * @readonly
   * @type {Promise <UserScanSettings>}
   * @memberof DatabaseService
   */
  get scanSettings(): Promise<UserScanSettings> {
    return this.afdb
      .object<UserScanSettings>(
        `/users/${this.authService.currentUserId}/settings/scan`
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  /**
   * update scan settings
   *
   * @param {userScanSettings} UserScanSettings
   * @returns {Promise<void>}
   * @memberof DatabaseService
   */
  saveScanSettings(userScanSettings: UserScanSettings): Promise<void> {
    return this.afdb
      .object(`/users/${this.authService.currentUserId}/settings/scan`)
      .update(userScanSettings);
  }

  /**
   * write all notification reads to true
   *
   * @param {string} key
   * @memberof DatabaseService
   */
  readNotification(key: string): void {
    this.afdb
      .object(`/notifications/${this.authService.currentUserId}/${key}`)
      .update({ read: true });
  }

  // get verifiedMarketplaces(): Observable<any> {
  //   return this.afdb
  //     .list(`/credentials/${this.authService.currentUserId}/`)
  //     .snapshotChanges()
  //     .pipe(
  //       map(changes =>
  //         changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
  //       ),
  //       map((marketplaces: any) => {
  //         const filtered = marketplaces.filter(marketplace => {
  //           return marketplace.verified === true;
  //         });
  //         return filtered.map(marketplace => marketplace.key);
  //       })
  //     );
  // }

  // now because the server is sp-api only.
  get deadlineAmazon() {
    return 1664564061000;
  }

  async verifiedMarketplaces() {
    const marketplaces = await this.getVerifiedSPMarketplaces().pipe(take(1)).toPromise();
    const mwsMarketplaces = await this.getVerifiedMWSMarketplaces().pipe(take(1)).toPromise();

    if (marketplaces.length >= mwsMarketplaces.length) {
      return marketplaces;
    } else {
      // MWS API is shutdown at 30 sept 2022, so we force to use SP-API
      if (new Date().getTime() > this.deadlineAmazon) {
        return marketplaces;
      }
      return mwsMarketplaces;
    }
  }


  getVerifiedSPMarketplaces(): Observable<any> {
    return this.afdb
      .list(`/credentialsSPAPI/${this.authService.currentUserId}/`)
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
        ),
        map((marketplaces: any) => {
          const filtered = marketplaces.filter(marketplace => {
            return marketplace.verified === true;
          });
          return filtered.map(marketplace => marketplace.key)
        })
      );
  }

  getVerifiedMWSMarketplaces(): Observable<any> {
    return this.afdb
      .list(`/credentials/${this.authService.currentUserId}/`)
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
        ),
        map((marketplaces: any) => {
          const filtered = marketplaces.filter(marketplace => {
            return marketplace.verified === true;
          });
          return filtered.map(marketplace => marketplace.key)
        }),
      );
  }

  async verifyUser(
    sellerId: string,
    authToken: string,
    region: string
  ): Promise<Observable<any>> {
    await this.http.post(`${environment.newApiUrl}/api/v1/credentials/${region}`, { sellerId, authToken }).toPromise()

    return this.afdb
      .object(`/credentials/${this.authService.currentUserId}/${region}/`)
      .valueChanges();
  }

  async verifyUserSPAPI(
    sellerID: string,
    code: string,
    region: string
  ): Promise<Observable<any>> {
    await this.http.post(`${environment.newApiUrl}/api/v1/spapi-credentials/${region}`, { sellerID, code }).toPromise()

    await (() => new Promise(res => setTimeout(res, 3000)))()

    return this.afdb
      .object(`/credentialsSPAPI/${this.authService.currentUserId}/${region}/`)
      .valueChanges();
  }

  // get intercomHash(): Promise<any> {
  //   return this.afdb
  //     .object(`users/${this.authService.currentUserId}/hash`)
  //     .valueChanges()
  //     .pipe(take(1))
  //     .toPromise();
  // }

  getUserSettingsEmail(): Observable<any> {
    return this.afdb.object(`users/${this.authService.currentUserId}/email`).valueChanges()
  }

  getZTFStatus(): Observable<any> {
    return this.afdb
      .list(`plus/${this.authService.currentUserId}/ztf`)
      .valueChanges()

  }

  getMembership(): Observable<any> {
    return this.afdb
      .object(`membership/${this.authService.currentUserId}`)
      .snapshotChanges()
      .pipe(
        take(1),
        map(data => {
          const payload = data.payload.val()
          return payload as Membership
        }
        ))
  }

  async updateZTFStatus(data: any) {
    return await this.afdb.database.ref(`plus/${this.authService.currentUserId}/ztf`).update(data)
  }

  async updateZTFData(index: number, data: any) {
    return await this.afdb.database.ref(`plus/${this.authService.currentUserId}/ztf/${index}`).update(data)
  }

  getDailyPicks():Observable<any> {
    //console.log('getDailies', this.authService.currentUserId)
    return this.afdb
      .list(`plus/${this.authService.currentUserId}/dailies`)
      .valueChanges()
  }

  async updateDailyPicks(data: any) {
    return await this.afdb.database.ref(`plus/${this.authService.currentUserId}/dailies`).set(data)
  }
}
