import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ProductDetail, Offer } from 'app/shared/models/sis/product-detail.model';
import { DatabaseService } from 'app/shared/services/database.service';
import { environment } from 'environments/environment';
import { MarketplaceCurrency, marketplaceCurrencies } from 'app/shared/models/marketplaceCurrencies.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserScanSettings } from 'app/shared/models/user.model';
import { AffiliateService } from 'app/shared/services/affiliate.service';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';
import { SisService } from 'app/shared/services/sis.service';
import { Router } from '@angular/router';
import { SubscriptionService } from 'app/shared/services/subscription.service';
import { AuthService } from 'app/shared/services/auth.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ScanMeta } from 'app/shared/models/scan-meta/scanMeta.model';
import { CurrencyService } from 'app/shared/services/currency.service';

@Component({
  selector: 'app-product-detail-asin',
  templateUrl: './product-detail-asin.component.html',
  styleUrls: ['./product-detail-asin.component.scss']
})
export class ProductDetailAsinComponent implements OnInit, OnDestroy {
  @Input() product: ProductDetail;
  @Input() marketplace: Marketplace;
  @Input() cost = 0;
  @Input() prepFee = 0;
  @Input() percentAdditional = 0;
  @Input() scanView: boolean;
  @Input() requestedCurrency: string;
  @Input() vat: number;
  @Input() costvat: number = 0;
  @Input() pricevat: number = 0;
  @Input() closingFee = 0;
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() toResults: EventEmitter<any> = new EventEmitter();
  @Input() condition: 'new' | 'used';
  @Input() fulfillmentMethod: 'FBA' | 'MFN' = 'FBA';
  @Input() scanDetailDetectionRef: ChangeDetectorRef
  @Input() estSales: number;
  @Input() productPrice: number;
  @Input() scanId: string;

  folderView: boolean
  price: number
  marketplaceCurrency: MarketplaceCurrency;
  category: string;
  domain: string;
  buyBoxOffer: Offer;
  environment = environment
  keepa30: string;
  keepa90: string;
  keepa365: string;
  activeKeepaTab: number = 0
  keepaSubject: Subject<number> = new Subject()
  userSettings: UserScanSettings;
  offersLoading = true;
  imgUrl: string
  restricted: boolean | null;
  extensionMissing: boolean;
  backTxt: string = 'Back to Results'
  destroy$ = new Subject<boolean>();
  scanMeta: ScanMeta;
  currencySymbol: string = '$'
  convertedValues;
  conversionLabel: string = 'Exchanged';
  lastScanPrice: string;
  exchangeRate: number = 1;
  converted: boolean = false;
  priceNotification:string;

  constructor(
    public authService: AuthService, // accessed in template
    public subscriptionService: SubscriptionService,
    private router: Router,
    private sisService: SisService,
    private affiliateService: AffiliateService,
    public databaseService: DatabaseService,
    private currencyService: CurrencyService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('amazon', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/amazon.svg'));
  }

  async ngOnInit() {
    //  console.log('price', this.productPrice, 'scanId', this.scanId)

    if (this.router.url.includes('folder')) {
      this.folderView = true
    }

    if (sessionStorage.getItem('dp') === '1') {
      this.backTxt = 'Back to Daily Picks'
    }

    this.marketplaceCurrency = marketplaceCurrencies[this.marketplace] as MarketplaceCurrency

    //TODO: convert displayed price based on user's settings

    //console.log('currency', this.marketplaceCurrency)
    window.getSelection().removeAllRanges();

    this.userSettings = await this.databaseService.scanSettings

    switch (this.marketplace.toLowerCase()) {
      case 'us':
        this.domain = 'com'
        break;
      case 'ca':
        this.domain = 'ca'
        break;
      case 'mx':
        this.domain = 'com.mx'
        break;
      case 'uk':
        this.domain = 'co.uk'
        break;
      case 'de':
        this.domain = 'de'
        break;
      case 'fr':
        this.domain = 'fr'
        break;
      case 'es':
        this.domain = 'es'
        break;
      case 'it':
        this.domain = 'it'
        break;
      default:
        break;
    }

    this.category = this.product.salesRankCategory ? this.product.salesRankCategory : 'N/A';


    this.keepa30 = `${environment.apiUrl}/KeepaGraph?marketplace=${this.marketplace}&asin=${this.product.asin}&range=30`
    this.keepa90 = `${environment.apiUrl}/KeepaGraph?marketplace=${this.marketplace}&asin=${this.product.asin}&range=90`
    this.keepa365 = `${environment.apiUrl}/KeepaGraph?marketplace=${this.marketplace}&asin=${this.product.asin}&range=365`

    this.keepaSubject.pipe(takeUntil(this.destroy$)).subscribe((event) => this.tabChange(event));

    if (this.estSales < 0 || !this.estSales) {
      this.product.estimatedSales = -2
    } else {
      this.product.estimatedSales = this.estSales
    }

    this.scanMeta = await this.databaseService.scanDetailMeta(this.scanId).toPromise()
    this.buyBoxOffer = this.product.offers.offers.find(offer => {
      if (this.condition.toLowerCase() === 'new') return offer.is.buyBox
      if (this.condition.toLowerCase() === 'used') return /used/i.test(offer.condition)
    })

    this.price = this.buyBoxOffer ? this.buyBoxOffer.landedPrice : 10

    //convert experimental
    try {
      if (this.scanMeta.currency !== this.marketplaceCurrency) {
        //let convertedPrice = this.price

        this.priceNotification = `Prices are converted from <b>${this.marketplaceCurrency}</b> to <b>${this.scanMeta.currency}</b> based on your <b>selected currency</b> in the scan settings.`
        const currency = { to: this.scanMeta.currency, from: String(this.marketplaceCurrency) }
        this.conversionLabel = `${this.marketplaceCurrency} to ${this.scanMeta.currency}`

        const conv = await this.currencyService.convertCurrency(currency)
        const convRatio = conv.value
        //this.productPrice = this.price * convRatio

        let d = this.product.offers.offers
        d.forEach((offer, i) => {
          d[i].landedPrice = offer.landedPrice * convRatio
        })
        // this.product.offers.offers = d

        this.exchangeRate = convRatio
        this.convertedValues = {
          current: this.product.buyBoxPrice.current * convRatio,
          90: this.product.buyBoxPrice[90] * convRatio,
          180: this.product.buyBoxPrice[180] * convRatio
        }
        this.product.buyBoxPrice.current *= convRatio
        this.product.buyBoxPrice[90] *= convRatio
        this.product.buyBoxPrice[180] *= convRatio
        
        //this.productPrice = this.convertedValues.current
        // = this.price

        switch (this.requestedCurrency) {
          case 'USD':
            this.currencySymbol = '$';
            break;
          case 'CAD':
            this.currencySymbol = 'CA$';
            break;
          case 'MXN':
            this.currencySymbol = 'MX$';
            break;
          case 'GBP':
            this.currencySymbol = '£';
            break;
          case 'EUR':
            this.currencySymbol = '€';
            break;
          default:
            this.currencySymbol = '$';
            break;
        }
        this.converted = true
      } else {
        this.converted = true
      }
    } catch (e) {
      this.productPrice = this.product.buyBoxPrice.current
      this.exchangeRate = 1
      this.requestedCurrency = this.marketplaceCurrency
      this.converted = true
    }

    this.offersLoading = false
    if (this.scanDetailDetectionRef) this.scanDetailDetectionRef.detectChanges()

    // check for restriction
    try {
      const domainEnd = this.affiliateService.findDomain(this.marketplace)
      //TODO - to check what error is this
      this.restricted = await this.sisService.isRestricted({ asin: this.product.asin, condition: this.condition, domainEnd })
    } catch (error) {
      this.extensionMissing = true
    }

    // if (this.product.rank > 0 && this.product.mwsCategory && this.marketplace === 'US') {
    //   this.sisService.getEstimatedSales(this.product.rank, this.marketplace, this.product.asin, this.product.mwsCategory).pipe(take(1)).subscribe(val => {
    //     this.product.estimatedSales = val
    //     if (this.scanDetailDetectionRef) this.scanDetailDetectionRef.detectChanges()
    //   })
    // }

  }

  backToScan() {
    this.back.emit();
  }

  backToResults() {
    this.toResults.emit()
  }

  amazonLink(asin: string) {
    this.affiliateService.affiliateLink(this.marketplace, asin)
  }

  ebayLink() {
    window.open(`https://www.ebay.com/itm/${this.product.ebayListingIds[0]}`, '_blank');
  }

  canSell(asin: string) {
    window.open(`https://sellercentral.amazon.${this.domain}/productsearch?q=${asin}`, '_blank');
  }

  tabChange(event: number) {
    this.activeKeepaTab = event
  }

  imageMouseOver() {
    let image = `https://images-na.ssl-images-amazon.com/images/I/${this.product.images[0]}`
    const largeImg = image.replace('_SL75_', '_SL500')
    this.imgUrl = largeImg
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
