import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Membership, PriceType } from 'app/shared/models/subscription/membership.model';
@Component({
  selector: 'app-cancellation-dialog',
  templateUrl: './cancellation-dialog.component.html',
  styleUrls: ['./cancellation-dialog.component.scss']
})
export class CancellationDialogComponent implements OnInit {
  cancellationForm: FormGroup
  cancellationReasons = ['Pricing Too Expensive', 'Not Using Enough', 'Missing Features', 'Did Not Work As Expected', 'Other']
  selectedRadio: string

  constructor(
    public dialogRef: MatDialogRef<CancellationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {currentPlan: Membership['price'], legacy: boolean}
    ) { }

  ngOnInit() {
    this.cancellationForm = new FormGroup({
      reason: new FormControl(null, Validators.required),
      reasonText: new FormControl(null, this.reasonTextValidation())
    })
  }

  submit(){
    if(this.cancellationForm.valid){
      const formVals = this.cancellationForm.value
      this.dialogRef.close({reason: formVals['reason'], reasonText: formVals['reasonText']})
    }
  }

  reasonTextValidation(): ValidatorFn{
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(this.selectedRadio === 'Other' && !control.value){
        return {required: true}
      } else{
        return null
      }
    };
  }

}
