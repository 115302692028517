import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadComponent } from './upload/upload.component';
import { MatDrawer } from '@angular/material/sidenav';
import { isEmpty } from 'lodash';
import { AuthService } from 'app/shared/services/auth.service';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import { DatabaseService } from 'app/shared/services/database.service';
import { take } from 'rxjs/operators';
import { ThemeService } from 'app/shared/services/theme.service';
import { HelpHeroService } from 'app/shared/services/helphero.service';
import { DiscordComponent } from './discord/discord.component';
import { SubscriptionService } from 'app/shared/services/subscription.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  config: MatDialogConfig = {
    hasBackdrop: true,
    width: '70%',
    maxHeight: '90vh',
    closeOnNavigation: true,
    autoFocus: false,
    data: {}
  };
  regions = {
    NA: ['US', 'MX', 'CA'],
    EU: ['UK', 'FR', 'IT', 'DE', 'ES'],
    BR: ['BR'],
    IN: ['IN'],
    CN: ['CN'],
    FE: ['AU', 'JP']
  };
  @Input()
  sideNavToggle: MatDrawer;

  discordConfig: MatDialogConfig = {
    hasBackdrop: true,
    height: '350px',
    width: '500px',
    closeOnNavigation: true,
    autoFocus: false
  };

  plusExpanded:boolean = false;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private snackbar: SnackbarService,
    private router: Router,
    private db: DatabaseService,
    private themeService: ThemeService,
    private helpHeroService: HelpHeroService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    try {
      this.helpHeroService.helpHero.on('tour_started', () => {
        this.sideNavToggle.open()
      })
    } catch (error) {
    }

    this.plusExpanded = this.router.url.includes('/plus')
  }

  expandPlus(e?:any) {

    this.plusExpanded = !this.plusExpanded
  }

  async openModal() {
    this.config.panelClass = this.themeService.darkTheme ? ['no-padding-dialog-container', 'dark-theme'] : ['no-padding-dialog-container']
    const regions = await this.db.verifiedMarketplaces();
    try {
     
      let emailVerified = this.authService.isEmailVerified();
      if (!emailVerified && !this.subscriptionService.isActive) throw new Error('email');

      if (isEmpty(regions)) {
        this.helpHeroService.helpHero.cancelTour()
        throw new Error('marketplace');
      }
      this.config.data.marketplaces = []
      regions.forEach(region => {
        this.config.data.marketplaces.push(...this.regions[region])
      })

      const dialogRef = this.dialog.open(UploadComponent, this.config);

      dialogRef.afterOpen().subscribe(() => {
        this.sideNavToggle.toggle();
      });
      dialogRef.afterClosed().subscribe((success) => {
        this.sideNavToggle.toggle();
      });

    } catch (e) {
      if (e.message === 'marketplace') {
        this.snackbar.showSnackbar('Add a marketplace to start scanning!');
        this.router.navigate(['/settings']);
      }
      if (e.message === 'email') {
        this.snackbar.showSnackbar(
          'Email is not verified! Click activation link in your welcome email!',
          {
            link: '/session/email?mode=verifyEmail&oobCode=none',
            linkMsg: 'Verify Now'
          }
        );
      }
    }
  }

  openDiscordModal() {
    this.discordConfig.panelClass = this.themeService.darkTheme ? ['no-padding-dialog-container', 'dark-theme', 'no-padding'] : ['no-padding-dialog-container', 'no-padding']
    this.discordConfig.data = { dark: this.themeService.darkTheme }
    const dialogRef = this.dialog.open(DiscordComponent, this.discordConfig);
  }
}
